import React, { useEffect, useState } from 'react'
import ClubDropDown from '../ClubDropDown'
import FixtureDropDown from './FixtureDropDown'
import TournamentDropDown from './TournamentDropDown'

import '../../styles/matchFilter.css'
import { CgSpinner } from 'react-icons/cg';
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export default function MatchFilter(props) {
    const [t, i18n] = useTranslation();
    const [club, setClub] = useState()
    const [fixture, setFixture] = useState("")
    const [tournament, setTournament] = useState("")
    const [search, setSearch] = useState(false)
    const [resetSearch, setResetSearch] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const { setMatches } = props

    useEffect(() => {
        if (search) setIsLoading(true)
        const getData = async () => {
            try {
                const res = await axios.get(`https://tazaker-back.onrender.com/api/match/filter?${query}`)
                setMatches(res.data)
                setIsLoading(false)

            } catch (err) {
                throw (err)
            }
        }
        let query = (fixture !== "") ? `fixture=${fixture}&` : ""
        if (tournament !== "")
            query += `leagueName=${tournament.replace(/\s/g, '')}&`
        if (club)
            query += `club=${club.clubName}`

        if (query !== "") {
            getData()
        }
    }, [search])

    useEffect(() => {
        setMatches([]);

    }, [resetSearch])

    return (
        <div className="match-filter-container">
            <h1 className="filter-title">{t('matches')}</h1>
            <div className="match-filter-list">
                <div className="match-filter-item">
                    <label htmlFor="club">{t('team')}</label>
                    <ClubDropDown selectedClub={setClub} reset={resetSearch} />
                </div>
                <div className="match-filter-item">
                    <label htmlFor="tournament" className="tournament">{t('tournament')}</label>
                    <TournamentDropDown selectedTournament={setTournament} reset={resetSearch} />
                </div>

                {<div className="match-filter-item">
                    <label htmlFor="fixture" className="fixture">{t('Fixture')}</label>
                    <FixtureDropDown selectedFixture={setFixture} disable={tournament !== "SaudiProLeague"} reset={resetSearch} />
                </div>}
            </div>
            <div className="buttons">
                <p className="reset-search" onClick={() => setResetSearch(!resetSearch)}>{t('clearSearch')}</p>
                <button type="submit" className='search-button' onClick={() => setSearch(!search)}>{isLoading ? <CgSpinner size={25} className="spinner" /> : t('search')}</button>
            </div>
        </div>
    )
}

