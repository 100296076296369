import React, { useEffect, useState } from 'react'
import useFetch from '../hooks/useFetch'
import '../styles/customDropDown.css'
import { useTranslation } from 'react-i18next';

export default function ClubDropDown(props) {
    const [t, i18n] = useTranslation();
    const { data, loading, error } = useFetch(`/club/`)
    const { selectedClub } = props
    let reset = props.reset
    const [dropDownTiltle, setDropDownTitle] = useState("all")
    useEffect(() => {
        setDropDownTitle('all')

    }, [reset])

    const getSelectedClub = (club) => {
        selectedClub(club);
        setDropDownTitle(t(JSON.stringify(club.clubName)))
    }

    return (
        <div className="dropdown">
            <button className="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {t(dropDownTiltle)}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {data.map(club => {
                    return (<li key={club._id} onClick={() => getSelectedClub(club)}>
                        <div className="container">
                            <img src={club.clubLogo} alt="" className="logo" width={25} height={30} />
                            <div className="name">{t(JSON.stringify(club.clubName))}</div>
                        </div>
                    </li>)
                })}


            </ul>
        </div>
    )
}
