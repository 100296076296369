import React from 'react';
import Navbar from '../components/Navbar'
import styles from '../styles/phoneOTPValidation.module.css';
import TextField from '../components/TextField';
import { useState } from 'react';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import { CgSpinner } from 'react-icons/cg';
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
import PhoneNumber from '../components/PhoneNumber';
import { PhoneNumberUtil } from 'google-libphonenumber';
import axios from 'axios';



export default function PhoneOTPValidation() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validPhone, setValidPhone] = useState(true);
    const [t, i18n] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [OTP, setOTP] = useState('');
    const [timer, setTimer] = useState(60);
    const [disableResend, setDisableResend] = useState(true);
    const navigate = useNavigate();
    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phoneNumber) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
        } catch (error) {
            return false;
        }
    };

    const sendOTP = async (phoneNumber) => {
        try {
            const response = await axios.post('https://tazaker-back.onrender.com/api/phone-otp/send', { phoneNumber: phoneNumber.split(" ").join("") });
            toast.success('OTP has been sent successfully');
            setShowOTP(true);
            setIsLoading(false);
            return response;
        } catch (error) {
            toast.error(error.message);
            setIsLoading(false);
            return error;
        }
    }

    const verifyOTP = async (phoneNumber, OTP) => {
        try {
            const response = await axios.post('https://tazaker-back.onrender.com/api/phone-otp/verify', { phoneNumber: phoneNumber.split(" ").join(""), OTP: OTP });
            // toast.success('Phone number is verified');
            setIsLoading(false);
            return response;
        } catch (error) {
            toast.error(error.message);
            setIsLoading(false);
            return error;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // setTimeout(() => { setIsLoading(false); toast.success('OTP sent successfully'); setShowOTP(true) }, 2000);

        // validation
        if (!phoneNumber || !validPhone) {
            toast.error('Invalid phone number');
            return;
        }
        const response = await sendOTP(phoneNumber);
    }

    const handleVerify = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // setTimeout(() => { setIsLoading(false); navigate('/reset-password'); }, 2000);
        // validation
        if (!OTP) {
            toast.error('Invalid OTP')
            return;
        }
        const response = await verifyOTP(phoneNumber, OTP);
        console.log(response);
        if (response.data.data.valid === true) {
            toast.success('Phone number is verified');
            navigate('/');
        }
        else {
            toast.error('Invalid OTP');
        }
    }

    const resendOTP = async (e) => {
        const response = await sendOTP(phoneNumber);
    }

    useEffect(() => {
        let interval = setInterval(() => {
            setTimer((lastCount) => {
                lastCount <= 1 && clearInterval(interval);
                if (lastCount <= 1) { setDisableResend(false) }
                if (lastCount <= 0) { return lastCount }
                return lastCount - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [disableResend]);

    return (
        <div className={styles.phone_verification_page}>
            <Navbar />
            {!showOTP ?
                <div className={styles.phone_verification_container}>
                    <h1>Phone Number Verification</h1>
                    <p>Enter your phone number</p>
                    <form name='phone_verification_form' onSubmit={handleSubmit}>
                        <PhoneNumber
                            label={t('phoneNumber')}
                            id='phoneNumber'
                            required={false}
                            defaultCountry='sa'
                            wrapperClassName={styles.form_group}
                            inputClassName={styles.text_field}
                            className={styles.phone_number}
                            inputStyle={{ fontSize: '16px' }}
                            value={phoneNumber}
                            onChange={(phoneNumber) => { setPhoneNumber(phoneNumber); setValidPhone(isPhoneValid(phoneNumber) || phoneNumber === "+966 "); }}
                        >
                            {(isPhoneValid(phoneNumber) || validPhone) ? '' : <span className={styles.error_msg}>Phone Number is not valid</span>}
                        </PhoneNumber>
                        <Button wrapperClassName={styles.button_container} className={styles.submit_button} disabled={isLoading} text={isLoading ? <CgSpinner size={25} className={styles.spinner} /> : t('submit')} />
                    </form>
                </div>
                :
                <div className={styles.otp_wrapper}>
                    <h1>OTP Verification</h1>
                    <p>Verify your phone number</p>
                    <OtpInput
                        value={OTP}
                        onChange={setOTP}
                        numInputs={6}
                        renderSeparator={<span style={{ width: "8px" }}></span>}
                        shouldAutoFocus={true}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={styles.otp_container}
                        inputStyle={styles.otp_input}
                    />
                    <Button wrapperClassName={styles.button_container} onClick={handleVerify} className={styles.verify_button} disabled={isLoading} text={isLoading ? <CgSpinner size={25} className={styles.spinner} /> : 'Verify'} />
                    <span style={{ color: 'black' }}>Did not receive code? <Link onClick={resendOTP} style={{ color: disableResend ? 'black' : '', textDecoration: 'none', cursor: disableResend ? 'not-allowed' : 'pointer' }}>Resend code</Link>{disableResend ? ` in ${timer} seconds` : ``}</span>
                </div>
            }


            <ToastContainer
                theme='colored'
                pauseOnHover
                draggable
                pauseOnFocusLoss
                newestOnTop={false}
                hideProgressBar
                position="bottom-right"
                autoClose={1000}
                rtl={false}
                closeButton={false}
            />
        </div>
    )
}
