import React, { useEffect, useMemo, useReducer, useState } from 'react'
import Navbar from '../components/Navbar'
import styles from '../styles/register.module.css';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import "react-datepicker/dist/react-datepicker.css";
import { reducer } from '../reducers/registerReducer';
import validator from 'validator';
import axios from 'axios';
import TextField from '../components/TextField';
import PhoneNumber from '../components/PhoneNumber';
import DatePicker from '../components/DatePicker';
import RadioButtonGroup from '../components/RadioButtonGroup';
import Button from '../components/Button';
import DropDownMenu from '../components/DropDownMenu';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { register, reset } from '../redux/authSlice';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next'
// import 'react-toastify/dist/ReactTostify.css';


const clubs = [
  'Al-Hilal',
  'Al-Ittihad',
  'Al-Taawoun',
  'AL NASSR',
  'Al-Ettifaq',
  'Al-Ahli',
  'Al-Wehda',
  'Al-Fateh',
  'Al-Feiha',
  'Al-Khaleej',
  'Al-Shabab',
  'Al-Ta\'ee',
  'Abha',
  'Damac',
  'Al-Akhdoud',
  'Al-Raed',
  'Al-Riyadh',
  'Al-Hazm'
];

const club_id = {
  'Al-Hilal': '',
  'Al-Ittihad': '65158b1b8b0fcd363ccce621',
  'Al-Taawoun': '',
  'Al-Nassr': '65158b438b0fcd363ccce626',
  'Al-Ettifaq': '',
  'Al-Ahli': '',
  'Al-Wehda': '',
  'Al-Fateh': '',
  'Al-Feiha': '',
  'Al-Khaleej': '',
  'Al-Shabab': '65158b368b0fcd363ccce624',
  'Al-Ta\'ee': '65158b4e8b0fcd363ccce628',
  'Abha': '',
  'Damac': '',
  'Al-Akhdoud': '',
  'Al-Raed': '',
  'Al-Riyadh': '65158b998b0fcd363ccce62a',
  'Al-Hazm': '65158c418b0fcd363ccce62c'
};

const ERROR_MESSAGES = {
  EMAIL_EXISTS: "User email already exists",
  PHONE_EXISTS: "User Phone Number already exists",
  NATIONAL_ID_EXISTS: "User National ID already exists"
};

export default function Register() {
  const [state, stateDispatch] = useReducer(reducer, {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    nationalId: '',
    nationality: '',
    city: '',
    address: '',
    gender: '',
    favoriteTeam: '',
    dateOfBirth: ''
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validPassword, setValidPassword] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [validNationalId, setValidNationalId] = useState(true);
  const [matchedPassword, setMatchedPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLoading, isSuccess, isError, message } = useSelector(state => state.auth);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phoneNumber) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
    } catch (error) {
      return false;
    }
  };

  const countryOptions = useMemo(() => countryList().getData(), []);

  const passwordHints = () => {
    return (
      <ul style={{ overflow: 'hidden', textAlign: 'start', color: 'red' }}>
        <li>Minimum length : 8</li>
        <li>Minimum lowercase letters : 1</li>
        <li>Minimum uppercase letters : 1</li>
        <li>Minimum numbers : 1</li>
        <li>Minimum symbols : 1</li>
      </ul>
    );
  }


  const validateEmail = (email) => {
    if (validator.isEmail(email)) {
      // console.log('Valid email');
      return true;
    }
    else {
      // document.querySelector('#email').focus();
      // console.log('Invalid email');
      return false;
    }
  }

  const validatePassword = (password) => {
    const constraints = {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1
    };
    if (validator.isStrongPassword(password, constraints)) {
      // console.log('Valid password');
      return true;
    }
    else {
      // console.log('Invalid password');
      return false;
    }
  }

  const matchPassword = (password, confirmPassword) => {
    return password === confirmPassword;
  }

  const validateNationalId = (id) => {
    let type = id.substr(0, 1);
    let sum = 0;
    const _idLength = 10;
    const _type1 = '1';
    const _type2 = '2';
    id = id.trim();
    if (isNaN(parseInt(id)) || (id.length !== _idLength) || (type !== _type2 && type !== _type1)) {
      return -1;
    }
    for (let num = 0; num < 10; num++) {
      const digit = Number(id[num]);
      if (num % 2 === 0) {
        const doubled = digit * 2;
        const ZFOdd = `00${doubled}`.slice(-2);
        sum += Number(ZFOdd[0]) + Number(ZFOdd[1]);
      } else {
        sum += digit;
      }
    }
    return (sum % 10 !== 0) ? -1 : type;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validation
    if (!state.firstName) {
      document.querySelector('#firstName').focus();
      return;
    }
    if (!state.lastName) {
      document.querySelector('#lastName').focus();
      return;
    }
    if (!validateEmail(state.email)) {
      document.querySelector('#email').focus();
      return;
    }
    if (!validatePassword(state.password)) {
      document.querySelector('#password').focus();
      return;
    }
    if (!matchPassword(state.password, confirmPassword)) {
      document.querySelector('#confirmPassword').focus();
      return;
    }
    if (!isPhoneValid(state.phoneNumber)) {
      return;
    }
    if (!state.nationality) {
      document.querySelector('#nationality').focus();
      return;
    }
    /*if (!validateNationalId(state.nationalId)) {
      if (state.nationality === "Saudi Arabia") {
        document.querySelector('#nationalId').focus();
      }
      else {
        document.querySelector('#eqamaId').focus();
      }
      return;
    }*/
    if (!state.city) {
      document.querySelector('#city').focus();
      return;
    }
    if (!state.dateOfBirth) {
      document.querySelector('#dateOfBirth').focus();
      return;
    }
    if (!state.gender) {
      document.querySelector('#gender').focus();
      return;
    }
    if (!state.favoriteTeam) {
      document.querySelector('#favoriteTeam').focus();
      return;
    }

    const user = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      password: state.password,
      phoneNumber: state.phoneNumber,
      nationalId: +state.nationalId,
      nationality: state.nationality.label,
      city: state.city,
      address: state.address,
      gender: state.gender,
      dateOfBirth: state.dateOfBirth,
      favoriteTeam: club_id[state.favoriteTeam],
      tickets: [],
      profilePicture: ''
    };

    dispatch(register(user));

    /*try {
      const response = await axios.post('https://tazaker-back.onrender.com/api/users/register', user);
      // store user in local storage
      localStorage.setItem('user', JSON.stringify({ user: { id: response.data.data.user._id, email: response.data.data.user.email, role: 'user' }, token: response.data.data.token }));
      console.log(response.data.data.user);
      // console.log(response);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      if (errorMsg === ERROR_MESSAGES.EMAIL_EXISTS) {
        setValidEmail(false);
        stateDispatch({ type: 'updateEmail', payload: '' });
        document.querySelector('#email').focus();
      }
      if (errorMsg === ERROR_MESSAGES.NATIONAL_ID_EXISTS) {
        setValidNationalId(false);
        stateDispatch({ type: 'updateNationalId', payload: '' });
        if (state.nationality === 'Saudi Arabia') {
          document.querySelector('#nationalId').focus();
        }
        else {
          document.querySelector('#eqamaId').focus();
        }

      }
      if (errorMsg === ERROR_MESSAGES.PHONE_EXISTS) {
        setValidPhone(false);
        stateDispatch({ type: 'updatePhoneNumber', payload: '' });
        // document.querySelector('#phoneNumber').focus();
      }
      console.log("Error : ")
      console.log(errorMsg);
    }*/

  }

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate('/');
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const [t, i18n] = useTranslation();

  return (
    <div className={styles.register}>
      <Navbar />
      <div className={styles.register_container}>
        <h1 style={{ color: 'black' }}>REGISTER</h1>
        <form name='register_form' onSubmit={handleSubmit}>
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.first_name + ' ' + styles.text_field} required={true} type='text' id='firstName' label={t('firstName')} onChange={(e) => stateDispatch({ type: 'updateFirstName', payload: e.target.value })} />
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.last_name + ' ' + styles.text_field} required={true} type='text' id='lastName' label={t('lastName')} onChange={(e) => stateDispatch({ type: 'updateLastName', payload: e.target.value })} />
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.email + ' ' + styles.text_field + ' ' + ((validateEmail(state.email) || validEmail) ? '' : styles.invalid)} required={true} type='email' id='email' label={t('emailAddress')} onChange={(e) => { stateDispatch({ type: 'updateEmail', payload: e.target.value }); setValidEmail(validateEmail(e.target.value)); setErrorMsg('') }}>
            {(validateEmail(state.email) || validEmail) || errorMsg === ERROR_MESSAGES.EMAIL_EXISTS ? '' : <span className={styles.error_msg}>Invalid email address</span>}
            {errorMsg === ERROR_MESSAGES.EMAIL_EXISTS ? <span className={styles.error_msg}>{errorMsg}</span> : ''}
          </TextField>
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.password + ' ' + styles.text_field + ' ' + ((validatePassword(state.password) || validPassword) ? '' : styles.invalid)} required={true} type='password' id='password' label={t('password')} onChange={(e) => { stateDispatch({ type: 'updatePassword', payload: e.target.value }); setValidPassword(validatePassword(e.target.value)); }}>
            {(validatePassword(state.password) || validPassword) ? '' : <><span className={styles.error_msg}>Password is not strong</span><div className={styles.password_hints}>{passwordHints()}</div></>}
          </TextField>
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.password + ' ' + styles.text_field + ' ' + ((matchPassword(state.password, confirmPassword) || matchedPassword) ? '' : styles.invalid)} required={true} type='password' id='confirmPassword' label={t('confirmPassword')} onChange={(e) => { setConfirmPassword(e.target.value); setMatchedPassword(matchPassword(state.password, e.target.value)) }}>
            {(matchPassword(state.password, confirmPassword) || matchedPassword) ? '' : <span className={styles.error_msg}>Password doesn't match</span>}
          </TextField>
          <PhoneNumber
            label={t('phoneNumber')}
            id='phoneNumber'
            required={true}
            defaultCountry='sa'
            wrapperClassName={styles.form_group}
            inputClassName={styles.text_field}
            className={styles.phone_number}
            inputStyle={{ fontSize: '16px' }}
            value={state.phoneNumber}
            onChange={(phoneNumber) => { stateDispatch({ type: 'updatePhoneNumber', payload: phoneNumber }); setValidPhone(isPhoneValid(phoneNumber) || phoneNumber === "+966 "); setErrorMsg('') }}
          >
            {(isPhoneValid(state.phoneNumber) || validPhone || errorMsg === ERROR_MESSAGES.PHONE_EXISTS) ? '' : <span className={styles.error_msg}>Phone Number is not valid</span>}
            {errorMsg === ERROR_MESSAGES.PHONE_EXISTS ? <span className={styles.error_msg}>{errorMsg}</span> : ''}
          </PhoneNumber>

          <div className={styles.form_group + ' ' + styles.nationality} style={{ marginTop: '5px', marginBottom: '10px' }}>
            <label htmlFor='nationality' style={{ fontWeight: 'bold' }}> {t('nationality')} <span style={{ color: 'red' }}> *</span></label>
            <div className={styles.nationality_select}>
              <Select placeholder='Choose country' id='nationality' options={countryOptions} value={state.nationality} onChange={(value) => {
                stateDispatch({ type: 'updateNationality', payload: value })
              }} />
            </div>
          </div>
          {state.nationality?.label === 'Saudi Arabia' ?
            <TextField wrapperClassName={styles.form_group} inputClassName={styles.national_id + ' ' + styles.text_field + ' ' + ((validateNationalId(state.nationalId) === 1 || validNationalId) ? '' : styles.invalid)} required={true} type='number' id='nationalId' label={t('nationalId')} onChange={(e) => { stateDispatch({ type: 'updateNationalId', payload: e.target.value }); setValidNationalId(validateNationalId(e.target.value) === 1); }} >
              {(validateNationalId(state.nationalId) === 1 || validNationalId) ? '' : <span className={styles.error_msg}>National ID is not valid</span>}
            </TextField>
            : state.nationality ?
              <TextField wrapperClassName={styles.form_group} inputClassName={styles.national_id + ' ' + styles.text_field + ' ' + ((validateNationalId(state.nationalId) === 2 || validNationalId) ? '' : styles.invalid)} required={true} type='number' id='eqamaId' label={t('eqamaId')} onChange={(e) => { stateDispatch({ type: 'updateNationalId', payload: e.target.value }); setValidNationalId(validateNationalId(e.target.value) === 2); }} >
                {(validateNationalId(state.nationalId) === 2 || validNationalId) ? '' : <span className={styles.error_msg}>Eqama ID is not valid</span>}
              </TextField>
              :
              ''
          }
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.city + ' ' + styles.text_field} required={true} type='text' id='city' label={t('city')} onChange={(e) => stateDispatch({ type: 'updateCity', payload: e.target.value })} />
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.address + ' ' + styles.text_field} type='text' id='address' label={t('address')} onChange={(e) => stateDispatch({ type: 'updateAddress', payload: e.target.value })} />
          <DatePicker
            wrapperClassName={styles.form_group}
            containerClassName={styles.date_picker_container}
            inputClassName={styles.text_field + ' ' + styles.date_of_birth}
            id='dateOfBirth'
            label={t('dateOfBirth')}
            required={true}
            datePickerWrapperClassName={styles.date_picker_wrapper}
            value={state.dateOfBirth}
            onSelect={(date) => stateDispatch({ type: 'updateDateOfBirth', payload: date })}
          />

          <RadioButtonGroup
            wrapperClassName={styles.form_group}
            label={t('gender')}
            radioButtonsContainerClassName={styles.radio_buttons}
            radioWrapperClassName={styles.radio_button}
            groupName='gender'
            id='gender'
            required={true}
            values={['Male', 'Female']}
            onChange={(e) => stateDispatch({ type: 'updateGender', payload: e.target.value })}
          />
          <DropDownMenu
            wrapperClassName={styles.form_group}
            className={styles.favorite_team + ' ' + styles.text_field}
            label={t('favTeam')}
            id='favoriteTeam'
            required={true}
            options={clubs}
            value={state.favoriteTeam}
            onChange={(e) => stateDispatch({ type: 'updateFavoriteTeam', payload: e.target.value })}
          />

          <Button wrapperClassName={styles.button_container} className={styles.submit_button} text='Submit' type='submit' />

        </form>
        <div style={{ height: '50px' }}>
          <span>Already have an account? <Link to='/login'>Login</Link> </span>
        </div>
      </div>
      <ToastContainer
        theme='colored'
        pauseOnHover
        draggable
        pauseOnFocusLoss
        newestOnTop={false}
        hideProgressBar
        position="bottom-right"
        autoClose={1000}
        rtl={false}
        closeButton={false}
      />
    </div>
  )
}
