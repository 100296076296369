import React, { useEffect, useState } from 'react'
import '../../styles/customDropDown.css'
import { useTranslation } from 'react-i18next';


export default function FixtureDropDown(props) {

    const [t, i18n] = useTranslation();

    const data = []
    for (let i = 1; i < 35; i++) {
        data.push("GW" + i)
    }
    const { selectedFixture, disable, reset } = props
    const [dropDownTiltle, setDropDownTitle] = useState("all")
    useEffect(() => {
        setDropDownTitle("all")

    }, [reset])


    const getSelectedFixture = (fixture) => {
        selectedFixture(fixture)
        setDropDownTitle(fixture)
    }
    return (
        <div className="dropdown">
            <button disabled={disable} className="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {t(dropDownTiltle)}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {data.map((fixture, index) => {
                    return (<li key={index} onClick={() => getSelectedFixture(fixture)}>
                        <div className="container">
                            <div className="name">{t(fixture)}</div>
                        </div>
                    </li>)
                })}


            </ul>
        </div>
    )
}
