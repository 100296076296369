import React, { useEffect, useMemo, useReducer, useState } from 'react'
import Navbar from '../components/Navbar'
import styles from '../styles/login.module.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { reducer } from '../reducers/loginReducer';
import validator from 'validator';
import axios from 'axios';
import Button from '../components/Button';
import TextField from '../components/TextField';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { login, moderatorLogin, reset } from '../redux/authSlice';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const [state, stateDispatch] = useReducer(reducer, {
    email: '',
    password: '',
    phoneNumber: '',
  });
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [isModerator, setIsModerator] = useState(false);
  const moderator_name_regex = /^moderator_[a-zA-Z]+_[0-9]+$/;
  const phoneUtil = PhoneNumberUtil.getInstance();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isSuccess, isLoading, isError, message } = useSelector(state => state.auth);
  const isPhoneValid = (phoneNumber) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
    } catch (error) {
      return false;
    }
  };

  const validateEmail = (email) => {
    if (validator.isEmail(email)) {
      setIsModerator(false);
      return true;
    }
    else if (moderator_name_regex.test(email)) {
      setIsModerator(true);
      return true;
    }
    else {
      setIsModerator(false);
      return false;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (!state.email || !validateEmail(state.email)) {
      setValidEmail(false);
      document.querySelector('#email').focus();
      return;
    }
    // if (!validateEmail(state.email)) {
    //   setValidEmail(false);
    //   document.querySelector('#email').focus();
    //   return;
    // }
    if (!state.password) {
      setValidPassword(false);
      document.querySelector('#password').focus();
      return;
    }

    // check if the entered email is moderator name
    if (isModerator) {
      const moderator = {
        moderatorName: state.email,
        password: state.password
      }
      dispatch(moderatorLogin(moderator));

      /*try {
        const response = await axios.post('https://tazaker-back.onrender.com/api/moderators/login', moderator);
        // store moderator in local storage
        localStorage.setItem('moderator', JSON.stringify({ moderator: { id: response.data.data.modrator._id, moderatorName: response.data.data.moderator.moderatorName, role: 'moderator' }, token: response.data.data.token }));
        console.log(response.data.data.moderator);
      } catch (error) {
        setErrorMsg(error.response.data.message);
        if (errorMsg === 'Incorrect password') {
          // setValidPassword(false);
        }
      }*/
    }
    else {
      const user = {
        email: state.email,
        password: state.password,
      };

      dispatch(login(user));

      /*try {
        const response = await axios.post('https://tazaker-back.onrender.com/api/users/login', user);
        // store user in local storage
        localStorage.setItem('user', JSON.stringify({ user: { id: response.data.data.user._id, email: response.data.data.user.email, role: 'user' }, token: response.data.data.token }));
        console.log(response.data.data.user);

      } catch (error) {
        setErrorMsg(error.response.data.message);
        if (errorMsg === 'Incorrect password') {
          // setValidPassword(false);
        }
      }*/
    }
  }

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate('/');
    }

    dispatch(reset());
    return () => {
      if (isSuccess || user) {
        window.location.reload();
      }
    };
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const [t, i18n] = useTranslation();
  return (
    <div className={styles.login}>
      <Navbar />
      <div className={styles.login_container}>
        <h1 style={{ color: 'black' }}>LOGIN</h1>
        <form name='login_form' onSubmit={handleSubmit}>
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.email + ' ' + styles.text_field + ' ' + (validEmail ? '' : styles.invalid)} type='text' id='email' label={t('emailAddress')} onChange={(e) => { stateDispatch({ type: 'updateEmail', payload: e.target.value }); if (!validEmail) { setValidEmail(validateEmail(e.target.value)) } }}>
            {validEmail ? '' : <span className={styles.error_msg}>Invalid email address</span>}
          </TextField>
          <TextField wrapperClassName={styles.form_group} inputClassName={styles.password + ' ' + styles.text_field + ' ' + (validPassword ? '' : styles.invalid)} type='password' id='password' label={t('password')} onChange={(e) => { stateDispatch({ type: 'updatePassword', payload: e.target.value }); if (!validPassword) { setValidPassword(e.target.value) } }}>
            {(errorMsg === 'Incorrect password' || !validPassword) ? <span className={styles.error_msg}>Invalid Password</span> : ''}
          </TextField>

          <Button wrapperClassName={styles.button_container} className={styles.submit_button} text={t('submit')} />
        </form>
        <div style={{ height: '30px' }}>
          <span>Forgot password? <Link to='/password-recovery' style={{ textDecoration: 'none' }}>Reset password</Link></span>
        </div>
        <div style={{ height: '30px' }}>
          <span>{t('doNotHaveAnAccount')} <Link to='/register' style={{ textDecoration: 'none' }}>{t('signUp')}</Link></span>
        </div>

      </div>
      <ToastContainer
        theme='colored'
        pauseOnHover
        draggable
        pauseOnFocusLoss
        newestOnTop={false}
        hideProgressBar
        position="bottom-right"
        autoClose={1000}
        rtl={false}
        closeButton={false}
      />
    </div>
  )
}
