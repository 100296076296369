import React, { useEffect, useRef } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import '../styles/notAllowedPopUp.css'
import { Link, useNavigate } from 'react-router-dom';
import CameraComponent from './Camera';

export default function NotAllowedPopUp(props) {
    const ref = useRef()
    const navigate = useNavigate()
    const { contentText, closeButtonText, openTrigger, resetTriger, cameraComponent, matchId } = props

    useEffect(() => {
        if (openTrigger)
            ref.current.open()
    }, [openTrigger])
    return (
        <Popup
            ref={ref}
            trigger={<></>}
            modal
            nested
        >
            {close => (
                <div className="moda">
                    <button className="close" onClick={() => {
                        resetTriger(false)
                        close()
                    }}>
                        &times;
                    </button>
                    <div className="conten">
                        <p>{contentText}</p>
                    </div>
                    {cameraComponent &&
                        <div className="conten">
                            <CameraComponent matchId={matchId} />
                        </div>
                    }
                    <div className="actions">

                        {!cameraComponent &&
                            <button
                                className="pop-button"
                                onClick={() => {
                                    if (closeButtonText === "Login")
                                        navigate('/login')
                                    resetTriger(false)
                                    close();
                                }}
                            >
                                {closeButtonText}
                            </button>
                        }
                    </div>
                </div>
            )}
        </Popup>
    )
}
