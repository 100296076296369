import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import styles from '../styles/newPassword.module.css';
import validator from 'validator';
import TextField from '../components/TextField';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import { useNavigate } from 'react-router';
import { CgSpinner } from 'react-icons/cg';
import { ToastContainer, toast } from 'react-toastify';



export default function NewPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [matchedPassword, setMatchedPassword] = useState(true);
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();

    const passwordHints = () => {
        return (
            <ul style={{ overflow: 'hidden', textAlign: 'start', color: 'red' }}>
                <li>Minimum length : 8</li>
                <li>Minimum lowercase letters : 1</li>
                <li>Minimum uppercase letters : 1</li>
                <li>Minimum numbers : 1</li>
                <li>Minimum symbols : 1</li>
            </ul>
        );
    }

    const validatePassword = (password) => {
        const constraints = {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1
        };
        if (validator.isStrongPassword(password, constraints)) {
            // console.log('Valid password');
            return true;
        }
        else {
            // console.log('Invalid password');
            return false;
        }
    }

    const matchPassword = (password, confirmPassword) => {
        return password === confirmPassword;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setTimeout(() => { setIsLoading(false); toast.success('Password has been changed!'); navigate('/login'); }, 2000);
    }

    return (
        <div className={styles.new_password_page}>
            <Navbar />
            <div className={styles.new_password_container}>
                <h1>Reset Password</h1>
                <p>Enter new password</p>
                <form name='new_passsword_form' onSubmit={handleSubmit}>
                    <TextField wrapperClassName={styles.form_group} inputClassName={styles.password + ' ' + styles.text_field + ' ' + ((validatePassword(password) || validPassword) ? '' : styles.invalid)} required={false} type='password' id='password' label='New Password' onChange={(e) => { setPassword(e.target.value); setValidPassword(validatePassword(e.target.value)); }}>
                        {(validatePassword(password) || validPassword) ? '' : <><span className={styles.error_msg}>Password is not strong</span><div className={styles.password_hints}>{passwordHints()}</div></>}
                    </TextField>
                    <TextField wrapperClassName={styles.form_group} inputClassName={styles.password + ' ' + styles.text_field + ' ' + ((matchPassword(password, confirmPassword) || matchedPassword) ? '' : styles.invalid)} required={false} type='password' id='confirmPassword' label='Confirm New Password' onChange={(e) => { setConfirmPassword(e.target.value); setMatchedPassword(matchPassword(password, e.target.value)) }}>
                        {(matchPassword(password, confirmPassword) || matchedPassword) ? '' : <span className={styles.error_msg}>Password doesn't match</span>}
                    </TextField>
                    <Button wrapperClassName={styles.button_container} className={styles.submit_button} disabled={isLoading} text={isLoading ? <CgSpinner size={25} className={styles.spinner} /> : t('submit')} />
                </form>
            </div>
            <ToastContainer
                theme='colored'
                pauseOnHover
                draggable
                pauseOnFocusLoss
                newestOnTop={false}
                hideProgressBar
                position="bottom-right"
                autoClose={1000}
                rtl={false}
                closeButton={false}
            />
        </div>
    )
}
