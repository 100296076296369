import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: "rgb(89, 136, 101)",
                color: "white",
                marginTop: "100px",
                paddingBottom: "15px"
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="white" gutterBottom>
                            About Us
                        </Typography>
                        <Typography variant="body2" color="white" style={{ textAlign: "left" }}>
                            We are at <strong></strong> , passionate about bringing sports and
                            entertainment enthusiasts closer to their favorite events.
                            We understand the excitement and anticipation that comes with
                            attending a live match, concert, or any other event. That's why we've made
                            it our mission to provide a seamless and reliable ticketing experience
                            for fans across the globe.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="white" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2" color="white">
                            123 Main Street, Anytown, USA
                        </Typography>
                        <Typography variant="body2" color="white">
                            Email: info@example.com
                        </Typography>
                        <Typography variant="body2" color="white">
                            Phone: +1 234 567 8901
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="white" gutterBottom>
                            Follow Us
                        </Typography>
                        <Link href="https://www.facebook.com/" color="inherit">
                            <Facebook />
                        </Link>
                        <Link
                            href="https://www.instagram.com/"
                            color="inherit"
                            sx={{ pl: 1, pr: 1 }}
                        >
                            <Instagram />
                        </Link>
                        <Link href="https://www.twitter.com/" color="inherit">
                            <Twitter />
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="white" align="center">
                        {"Copyright © "}

                        {new Date().getFullYear()}
                        {"  "}
                        <Link color="inherit" href="https://your-website.com/">
                            tazaker-front.onrender.com
                        </Link>{" "}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}