import React, { Component, useState } from 'react';
import Webcam from 'react-webcam';
import '../styles/cameraComponent.css'
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useSelector } from 'react-redux';
export default function CameraComponent(props) {
    const webcamRef = React.createRef();
    const matchId = props.matchId;
    const [state, setState] = useState({
      capturedImage: null,
    })
    const navigate = useNavigate()
    const capture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setState({ capturedImage: imageSrc });
      console.log(imageSrc);
    };

    const retakePhoto = () => {
      setState({ capturedImage: null });
    };
    const { user } = useSelector(state => state.auth);

    
    const bookTicket = async () => {
      console.log("==============================Started the hell checkout ===========================================")
      console.log("token in cam is:"+user.user.id)
      console.log("token in cam is:"+user.token)

      const config = {
        headers: { 
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        }
      };
      localStorage.setItem('ticketImg',JSON.stringify(capturedImage));
      console.log("elsoraaa")
      console.log(capturedImage)
      const res =  await axios.patch(`https://tazaker-back.onrender.com/api/users/${user.user.id}`,{
        "tikcetPhoto": capturedImage
      },config);
      console.log("nategt elpatch:");
      console.log(res);
      // const pic = JSON.parse(localStorage.getItem('ticketImg'));
      // console.log(pic);
      navigate(`/bookTicket/${matchId}`);
    };

    const { capturedImage } = state;
    return (
      <div className='cam'>
        {capturedImage ? (
          <div className='cam'>
            <img src={capturedImage} alt="Captured" />
            <button className="green-button left-button" onClick={retakePhoto}>Take Another Photo</button>
            <button className="green-button" onClick={bookTicket}>Continue Booking</button>
          </div>
        ) : (
          <div className='cam'>
            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className='cam'/>
            <button className="green-button" onClick={capture}>Capture Photo</button>
          </div>
        )}
      </div>
    );
}

