import React from 'react'

export default function Button(props) {
    const { wrapperClassName, className, text, type, onClick, disabled = false } = props;
    return (
        <div className={wrapperClassName}>
            <button className={className} type={type} onClick={onClick} disabled={disabled}><span>{text}</span></button>
        </div>
    )
}
