import React from 'react'

export default function DropDownMenu(props) {
    const { wrapperClassName, className, disabled, required, labelClassName, label, id, options, value, onChange } = props;
    return (
        <div className={wrapperClassName}>
            <label className={labelClassName} htmlFor={id} style={{ fontWeight: 'bold' }}>{label}{required ? <span style={{ color: 'red' }}> *</span> : ''}</label>
            <select className={className} disabled={disabled} value={value} onChange={onChange} id={id} >
                <option hidden> -- Select an option -- </option>
                {options.map((option) => <option value={option}>{option}</option>)}
            </select>
        </div>
    )
}
