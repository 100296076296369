import React from 'react'
import Navbar from '../components/Navbar'
import TicketCard from '../components/TicketCard'
import styles from '../styles/mytickets.module.css';

export default function MyTickets() {
    return (
        <div className={styles.mytickets_page}>
            <Navbar />
            <TicketCard userName={'Youssef Mostafa'} ticketId={''} userId={''} cancelled={false} />
            <TicketCard userName={'Youssef Mostafa'} ticketId={''} userId={''} cancelled={false} />
            <TicketCard userName={'Youssef Mostafa'} ticketId={''} userId={''} cancelled={true} />
            <TicketCard userName={'Youssef Mostafa'} ticketId={''} userId={''} cancelled={false} />
        </div>
    )
}
