import React from 'react'
import profilePicture from '../assets/images/userImg.JPG'
import styles from '../styles/ticketCard.module.css';
import Button from './Button';
import MatchCard from './MatchCard';
import hilal from '../assets/images/logos/AL HILAL.svg';
import nassr from '../assets/images/logos/AL NASSR.svg';
import stadiumLogo from '../assets/images/stadium.svg';
import calenderLogo from '../assets/images/calendar.svg';
import seatLogo from '../assets/images/seat.svg';

export default function TicketCard(props) {
    const { userName, ticketId, userId, cancelled } = props;
    // const { seat, match, user, isCanceled, qrcode, club } = props.ticketInfo
    // const match = {
    //     date: '2023-10-14T19:00:00.000+00:00',
    //     homeClub: '65158b438b0fcd363ccce626',
    //     awayClub: '65158c418b0fcd363ccce62c',
    //     stadium: '65208a4947de688e181d2899',
    //     leagueName: "SaudiProLeague",
    //     fixture: "GW14"
    // };

    return (
        <div className={styles.ticket_card}>
            <div className={styles.ticket_card_container}>
                <div className={styles.ticket_header}>
                    <div className={styles.user_name}>
                        <h6 style={{ fontWeight: 'bold' }} className={styles.h6}>Name</h6>
                        <h6 className={styles.h6}>Youssef Mostafa</h6>
                    </div>
                    <div className={styles.vertical_line} >
                    </div>
                    <div className={styles.ticket_id}>
                        <h6 style={{ fontWeight: 'bold' }} className={styles.h6}>Ticket No.</h6>
                        <h6 className={styles.h6}>1038008115664942</h6>
                    </div>
                    <div className={styles.vertical_line} >
                    </div>
                    <div className={styles.user_id}>
                        <h6 style={{ fontWeight: 'bold' }} className={styles.h6}>User ID</h6>
                        <h6 className={styles.h6}>10101572800072</h6>
                    </div>
                    <div className={styles.vertical_line}>
                    </div>
                    <div className={styles.ticket_status}>
                        <h6 style={{ fontWeight: 'bold' }} className={styles.h6}>Status</h6>
                        {cancelled ? <h6 className={styles.h6} style={{ color: 'red', fontWeight: 'bold' }}>Cancelled</h6> : <h6 className={styles.h6} style={{ color: 'green', fontWeight: 'bold' }}>Confirmed</h6>}
                    </div>
                    <div className={styles.container}>
                        <Button wrapperClassName={styles.button_container} className={styles.view_button} disabled={cancelled} text='View' type='' onClick={() => { }} />
                    </div>
                </div>
                <div className={styles.ticket_body}>
                    <div className={styles.teams}>
                        <div className={styles.home_club}>
                            <div className={styles.club_logo}>
                                <img src={hilal} alt='' width={40} height={40} />
                            </div>
                            <div className={styles.club_name}>
                                <h5 style={{ fontWeight: 'bold' }} className={styles.h5}>AL HILAL</h5>
                            </div>
                        </div>
                        <h3 className={styles.h3}>VS</h3>
                        <div className={styles.away_club}>
                            <div className={styles.club_logo}>
                                <img src={nassr} alt='' width={40} height={40} />
                            </div>
                            <div className={styles.club_name}>
                                <h5 style={{ fontWeight: 'bold' }} className={styles.h5}>AL NASSR</h5>
                            </div>
                        </div>
                    </div>
                    <div className={styles.stadium}>
                        <div className={styles.stadium_logo}>
                            <img src={stadiumLogo} alt='' width={50} height={50} />
                        </div>
                        <div className={styles.stadium_details}>
                            <h5 style={{ fontWeight: 'bold' }} className={styles.h5}>King Fahd International Stadium</h5>
                            <h6 className={styles.h6}>Riyadh</h6>
                        </div>
                    </div>
                    <div className={styles.date_seat_container}>
                        <div className={styles.date}>
                            <div className={styles.date_logo}>
                                <img src={calenderLogo} alt='' width={50} height={50} />
                            </div>
                            <div className={styles.date_details}>
                                <h5 style={{ fontWeight: 'bold' }} className={styles.h5}>Sun 04 Jun 2023</h5>
                                <h6 className={styles.h6}>Time : 09 : 00 PM</h6>
                            </div>
                        </div>
                        <div className={styles.seat}>
                            <div className={styles.seat_logo}>
                                <img src={seatLogo} alt='' width={50} height={50} />
                            </div>
                            <div className={styles.seat_details}>
                                <h5 style={{ fontWeight: 'bold' }} className={styles.h5}>G1</h5>
                                <div className={styles.row_seat}>
                                    <h6 className={styles.h6}>Row : 15</h6>
                                    <h6 className={styles.h6}>Seat : 5</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ div>
        </div>
    )
}
