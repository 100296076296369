import React, { forwardRef, useEffect, useRef, useState } from 'react'
import '../styles/matchCard.css'
import useFetch from '../hooks/useFetch'
import stadiumLogo from '../assets/images/stadium.svg'
import calendarLogo from '../assets/images/calendar.svg'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import NotAllowedPopUp from './NotAllowedPopUp'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css';

import '../styles/notAllowedPopUp.css'
import CameraComponent from './Camera'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export default function MatchCard(props) {
    const [t, i18n] = useTranslation();


    const { user } = useSelector(state => state.auth);
    const { homeClub, awayClub, stadium, fixture, date, leagueName, _id: matchId } = props.matchInfo
    const navigate = useNavigate()
    const dateNow = new Date();
    const [pop, setPop] = useState(false)
    const [closeButtonText, setCloseButtonText] = useState("")
    const [popUpContentText, setPopUpContentText] = useState("")
    const [cameraComponent, setcameraComponent] = useState("")
    const [available, setAvailable] = useState("")
    // useEffect(() => {
    //     async () => {
    //         await axios.get(`https://tazaker-back.onrender.com/api/tickets?userId=${user.user.id}&matchId=${user.id}`)
    //     }
        
    //     }, [])
    const bookHandler = () => {
        // navigate(`/bookTicket/${matchId}`)
        if (!user) {
            setCloseButtonText("Login");
            setPopUpContentText("Please login first so you can attend your favorite team match !!")
            setPop(true)

        } else {
            if (user.user.club === homeClub.clubName || user.user.club === awayClub.clubName){
                localStorage.setItem('homeClubLogo',JSON.stringify(homeClub.clubLogo));
                localStorage.setItem('awayClubLogo',JSON.stringify(awayClub.clubLogo));
                // const pic = JSON.parse(localStorage.getItem('ticketImg')); 
                setPop(true);
                setcameraComponent(true);
                // navigate(`/bookTicket/${matchId}`);
            } else {
                setCloseButtonText("Select Another Match");
                setPopUpContentText(`You can't book a ticket for this Match as your favorite club isn't one of ${homeClub.clubName} neither ${awayClub.clubName}`)

                setPop(true)
            }
        }
    }

    return (
        <div className='match-card'>
            <div className="match-card-container">
                <div className="match-card-list">
                    <div className="match-card-up">
                        <div className="clubs">
                            <div className="match-card-item">
                                <div className="club">
                                    <img src={homeClub?.clubLogo} alt="" className="club-logo" />
                                    <p className="club-name">{t(JSON.stringify(homeClub?.clubName))}</p>
                                </div>
                                <p style={{ margin: "0px" }}>VS</p>
                                <div className="club">
                                    <img src={awayClub?.clubLogo} alt="" className="club-logo" />
                                    <p className="club-name">{t(JSON.stringify(awayClub?.clubName))}</p>
                                </div>
                            </div>
                        </div>
                        <div className="stadium">
                            <img src={stadiumLogo} alt="" className="stadium-logo" />
                            <div className="stadium-text">
                                <p className="stadium-name">{stadium.name}</p>
                                <p className="stadium-city">{stadium.city}</p>
                            </div>
                        </div>
                        <div className="match-date-list">
                            <img src={calendarLogo} alt="" className="calendar-logo" />
                            <div className="date-text">
                                <p className="date">{new Date(date).toDateString()}</p>
                                <p className="time">{new Date(date).toLocaleTimeString([], { timeStyle: 'short' })}</p>
                            </div>
                        </div>
                        {
                            // dateNow.getTime() > new Date(date).getTime() ?
                            true ?
                            <button className="book-ticket" onClick={bookHandler}>{t("BookTicket")}</button> :
                            <button className="book-ticket" disabled={true}>Outdated</button>
                        }
                    </div>
                    <div className="match-card-bottom">
                        <div className="tournament">{t(leagueName.replace(/\s/g, ''))}</div>
                        <div className="fixture">{t(fixture.replace(/\s/g, ''))}</div>
                        <div className="available">{available}</div>
                    </div>
                </div>
            </div>
            <NotAllowedPopUp contentText={popUpContentText} closeButtonText={closeButtonText} openTrigger={pop} resetTriger={setPop} cameraComponent={cameraComponent} matchId={matchId} />
        </div>
    )
}
