import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt from 'jwt-decode'
import axios from "axios";

// get user from localStorage
const user = JSON.parse(localStorage.getItem('user'));

// get moderator from localstorage
const moderator = JSON.parse(localStorage.getItem('moderator'));

// get moderator from localstorage
const admin = JSON.parse(localStorage.getItem('admin'));


const initialState = {
    user: user ? user : null,
    moderator: moderator ? moderator : null,
    admin: admin ? admin : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

// register user
export const register = createAsyncThunk('auth/register', async(user, thunkAPI) => {
    try {
        const response = await axios.post('https://tazaker-back.onrender.com/api/users/register', user);
        // store user in local storage
        localStorage.setItem('user', JSON.stringify({ user: { id: response.data.data.user._id, email: response.data.data.user.email, role: 'user', firstName: response.data.data.user.firstName, lastName: response.data.data.user.lastName, phoneNumber: response.data.data.user.phoneNumber, nationalId: response.data.data.user.nationalId }, token: response.data.data.token }));
        console.log(response.data.data.user);
        return response.data.data.user;
    } catch (error) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
    }
});

// login user
export const login = createAsyncThunk('auth/login', async(user, thunkAPI) => {
    try {
        // const response = await axios.post('https://tazaker-back.onrender.com/api/users/login', user, { withCredentials: true });
        const response = await axios.post('https://tazaker-back.onrender.com/api/users/login', user);
        // store user in local storage
        // localStorage.setItem('user', JSON.stringify({ user: { id: response.data.data.user._id, email: response.data.data.user.email, club: response.data.data.user.favoriteTeam, role: 'user' }, token: response.data.data.token }, ));
        localStorage.setItem('user', JSON.stringify({ user: response.data.data.user, token: response.data.data.accessToken }, ));
        console.log(response.data.data.user);
        return response.data.data.user;
    } catch (error) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
    }
});

// refresh token
export const refresh = createAsyncThunk('auth/refresh', async(user, thunkAPI) => {
    try {
        const response = await axios.get('https://tazaker-back.onrender.com/api/users/refresh-token')
        return response.data.data.accessToken;
    } catch (error) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
    }
});

// login moderator
export const moderatorLogin = createAsyncThunk('auth/moderatorLogin', async(moderator, thunkAPI) => {
    try {
        const response = await axios.post('https://tazaker-back.onrender.com/api/moderators/login', moderator);
        // store user in local storage
        localStorage.setItem('moderator', JSON.stringify({ moderator: { id: response.data.data.moderator._id, email: response.data.data.moderator.email, role: 'moderator' }, token: response.data.data.token }));
        // console.log(response.data.data.moderator);
        console.log('Hello Moderator');
        return response.data.data.moderator;
    } catch (error) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
    }
});

// login admin
export const adminLogin = createAsyncThunk('auth/adminLogin', async(admin, thunkAPI) => {
    try {
        const response = await axios.post('https://tazaker-back.onrender.com/api/admin/login', admin);
        // store user in local storage
        localStorage.setItem('admin', JSON.stringify({ admin: { id: response.data.data.admin._id, email: response.data.data.admin.email, role: 'admin' }, token: response.data.data.token }));
        // console.log(response.data.data.moderator);
        console.log('Hello admmmin');
        return response.data.data.admin;
    } catch (error) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
    }
});

// logout user
export const logout = createAsyncThunk('auth/logout', async() => {
    localStorage.removeItem('user');
});

// logout moderator
export const moderatorLogout = createAsyncThunk('auth/moderatorLogout', async() => {
    localStorage.removeItem('moderator');
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.user = null;
            state.moderator = null;
            state.admin = null;
        }
    },
    extraReducers: (builder) => {
        builder.
        addCase(register.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null
            }).addCase(login.pending, (state) => {
                state.isLoading = true;
                state.user = null;
                state.isSuccess = false;
                state.isError = false;
                state.message = '';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.message = '';
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
                state.isSuccess = false;
            })
            .addCase(moderatorLogin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(moderatorLogin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.moderator = action.payload;
            })
            .addCase(moderatorLogin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.moderator = null
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
            })
            .addCase(moderatorLogout.fulfilled, (state) => {
                state.user = null;
            })
            .addCase(adminLogin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(adminLogin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.admin = action.payload;

            })
            .addCase(adminLogin.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.admin = null;
                state.message = action.payload;
            })
    }
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;

/* ============================================================================================================= */

// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import jwt from 'jwt-decode'
// import axios from "axios";

// // get user from localStorage
// // const user = JSON.parse(localStorage.getItem('user'));

// // get moderator from localstorage
// // const moderator = JSON.parse(localStorage.getItem('moderator'));

// const initialState = {
//     user: null,
//     moderator: null,
//     token: null,
//     isError: false,
//     isSuccess: false,
//     isLoading: false,
//     message: ''
// };

// // register user
// export const register = createAsyncThunk('auth/register', async(user, thunkAPI) => {
//     try {
//         const response = await axios.post('https://tazaker-back.onrender.com/api/users/register', user);
//         return response.data.data;
//     } catch (error) {
//         const message = error.response.data.message;
//         return thunkAPI.rejectWithValue(message);
//     }
// });

// // login user
// export const login = createAsyncThunk('auth/login', async(user, thunkAPI) => {
//     try {
//         const response = await axios.post('https://tazaker-back.onrender.com/api/users/login', user, { withCredentials: true });
//         return response.data.data;
//     } catch (error) {
//         const message = error.response.data.message;
//         return thunkAPI.rejectWithValue(message);
//     }
// });

// // refresh token
// export const refresh = createAsyncThunk('auth/refresh', async(user, thunkAPI) => {
//     try {
//         const response = await axios.get('https://tazaker-back.onrender.com/api/users/refresh-token', { withCredentials: true })
//         return response.data.data.accessToken;
//     } catch (error) {
//         const message = error.response.data.message;
//         return thunkAPI.rejectWithValue(message);
//     }
// });

// // login moderator
// export const moderatorLogin = createAsyncThunk('auth/moderatorLogin', async(moderator, thunkAPI) => {
//     try {
//         const response = await axios.post('https://tazaker-back.onrender.com/api/moderators/login', moderator);
//         // store user in local storage
//         localStorage.setItem('moderator', JSON.stringify({ moderator: { id: response.data.data.moderator._id, email: response.data.data.moderator.email, role: 'moderator' }, token: response.data.data.token }));
//         // console.log(response.data.data.moderator);
//         console.log('Hello Moderator');
//         return response.data.data.moderator;
//     } catch (error) {
//         const message = error.response.data.message;
//         return thunkAPI.rejectWithValue(message);
//     }
// });

// // logout user
// // export const logout = createAsyncThunk('auth/logout', async() => {
// //     localStorage.removeItem('user');
// // });

// // logout moderator
// export const moderatorLogout = createAsyncThunk('auth/moderatorLogout', async() => {
//     localStorage.removeItem('moderator');
// });

// export const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         reset: (state) => {
//             state.isLoading = false;
//             state.isSuccess = false;
//             state.isError = false;
//             state.message = '';
//             state.user = null;
//             state.moderator = null;
//             state.token = null;
//         },
//         logout: (state, action) => {
//             state.user = null;
//             state.token = null;
//         }
//     },
//     extraReducers: (builder) => {
//         builder.
//         addCase(register.pending, (state) => {
//                 state.isLoading = true;
//             })
//             .addCase(register.fulfilled, (state, action) => {
//                 state.isLoading = false;
//                 state.isSuccess = true;
//                 state.user = action.payload.user;
//                 state.token = action.payload.accessToken;
//             })
//             .addCase(register.rejected, (state, action) => {
//                 state.isLoading = false;
//                 state.isError = true;
//                 state.message = action.payload;
//                 state.user = null;
//                 state.token = null;
//             }).addCase(login.pending, (state) => {
//                 state.isLoading = true;
//                 state.user = null;
//                 state.token = null;
//                 state.isSuccess = false;
//                 state.isError = false;
//                 state.message = '';
//             })
//             .addCase(login.fulfilled, (state, action) => {
//                 state.isLoading = false;
//                 state.isError = false;
//                 state.message = '';
//                 state.isSuccess = true;
//                 state.user = action.payload.user;
//                 state.token = action.payload.accessToken;
//             })
//             .addCase(login.rejected, (state, action) => {
//                 state.isLoading = false;
//                 state.isError = true;
//                 state.message = action.payload;
//                 state.user = null;
//                 state.token = null;
//                 state.isSuccess = false;
//             })
//             .addCase(moderatorLogin.pending, (state) => {
//                 state.isLoading = true;
//             })
//             .addCase(moderatorLogin.fulfilled, (state, action) => {
//                 state.isLoading = false;
//                 state.isSuccess = true;
//                 state.moderator = action.payload;
//             })
//             .addCase(moderatorLogin.rejected, (state, action) => {
//                 state.isLoading = false;
//                 state.isError = true;
//                 state.message = action.payload;
//                 state.moderator = null
//             })
//             // .addCase(logout.fulfilled, (state) => {
//             //     state.user = null;
//             // })
//             .addCase(moderatorLogout.fulfilled, (state) => {
//                 state.user = null;
//             })
//     }
// });

// export const { reset, logout } = authSlice.actions;
// export default authSlice.reducer;