import React from 'react'
import '../styles/Ticket.css'
import PhoneIcon from '@mui/icons-material/Phone';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import { useTranslation } from 'react-i18next';

export default function Ticket(props) {
    console.log("props is ")
    console.log(props)
    let { 
        userName, 
        phoneNumber,
        userPic, 
        nationalId, 
        fixture, 
        matchDate, 
        homeClubName, 
        awayClubName, 
        homeClubLogo, 
        awayClubLogo, 
        stadiumName,
        qrImg,
        section,
        row,
        seat,
        bookingId,
        price
    } = props.info;
    const [t,i18n] = useTranslation();
    return (
        <div className='tiketbody'>
            <div className="m-ticket">
                
            <p className="m">{t('cutTicket')}</p>
                
                

                <div className="upper-details">
                    <img src={userPic} className="roshn"/>
                    
                    
                    <div className="movie">
                    <h4>{userName}</h4>
                    
                    <p><PhoneIcon className='phone-size'/>  {phoneNumber}</p>
                    <p><RecentActorsIcon className='id-size'/> {nationalId}</p>
                    </div>
                    <img src='https://www.saff.com.sa/uploadcenter/saffchamplarge1661278136.png' width={"40px"} height={"40px"}/>
                    
                    
                    
                </div>
                <hr/>
                <div className="movie-details">
                    <img src={homeClubLogo} className="poster"/>
                    
                    <div className="movie">
                        <h4>{t(homeClubName)}&nbsp;vs&nbsp;{t(awayClubName)}</h4>
                        <p> {stadiumName}, {fixture}</p>
                        <p> {matchDate} </p>
                    </div>
                    <img src={awayClubLogo} className="poster"/>
                    
                </div>
                
                <div className="ticket-details">
                    <img src={qrImg} className="scan"/>
                    
                    <div className="ticket">
                        <p><b><i>{t("section")} </i>{section}</b></p>
                        <p><EventSeatIcon /> &nbsp;&nbsp;{t("seat")} {seat}&nbsp;&nbsp;,&nbsp;&nbsp;{t("row")} {row}</p>
                        
                        <p>{t("BOOKINGID")}: {bookingId}</p>
                    
                    </div>
                    
                </div>
                
                <div className="info-cancel">
                    {t("cancellationNotAvailableForThisVenue")}
                </div>
                
                <div className="total-amount">
                    <p>{t("totalAmount")}: {price} {t("sar")} </p>
                </div>
            </div>
        </div>
    )
}
