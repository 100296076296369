import React from 'react'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

export default function PhoneNumber(props) {
    const { wrapperClassName, labelClassName, inputClassName, className, disabled, required, label, defaultCountry, value, onChange, inputStyle, id } = props;
    return (
        <div className={wrapperClassName}>
            <label className={labelClassName} htmlFor={id} style={{ fontWeight: 'bold' }}>{label}{required ? <span style={{ color: 'red' }}> *</span> : ''}</label>
            <PhoneInput defaultCountry={defaultCountry}
                value={value}
                disabled={disabled}
                onChange={onChange}
                inputClassName={inputClassName}
                inputStyle={inputStyle}
                className={className}
            />
            {props.children}
        </div>
    )
}
