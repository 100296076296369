
import React, { useState, useEffect } from 'react'
import '../styles/navbar.css'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, moderatorLogout, reset } from '../redux/authSlice'
import humbergerBars from '../assets/images/menu-icon.png'
import PublicIcon from '@mui/icons-material/Public'
import UserAvatar from './UserAvatar'


export default function Navbar(props) {
    const [t, i18n] = useTranslation();
    const { isLoggedIn = false, isProcessing = false } = props
    const { user, moderator } = useSelector(state => state.auth);
    const [showNavbar, setShowNavbar] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleShowNavbar = () => {
        console.log("HEREEE")
        setShowNavbar(!showNavbar)
    }

    const logoutHandler = () => {
        if (user) {
            dispatch(logout());
            dispatch(reset());
        }
        else if (moderator) {
            dispatch(moderatorLogout());
            dispatch(reset());
        }

        navigate('/');
    }

    const changeLanguage = (e) => {
        if (i18n.language === 'en') {
            i18n.changeLanguage('ar');
        } else {
            i18n.changeLanguage('en');
        }
    }

    useEffect(() => {
        console.log("Hello from Navbar !");
        console.log(user);
    }, [user]);
    return (
        <div className="navbar-container">

            <div className="navbar-logo">
                <Link style={{ textDecoration: 'none', color: 'white' }} to={'/'}>
                    <h1>{t('title')}</h1>
                </Link>
                {/* <img src="" alt="" className="" /> */}
            </div>
            <div className="menu-icon" onClick={handleShowNavbar}>
                <img src={humbergerBars} alt="" className='menu-icon-img' />
            </div>
            <div className={`navbar-list ${showNavbar && 'active'}`}>
                {user && <UserAvatar />}
                <div className="navbar-item">
                    <Link style={{ textDecoration: 'none', color: 'white' }}>{t('matches')}</Link>
                </div>

                {(user) && <div className="navbar-item">
                    <Link style={{ textDecoration: 'none', color: 'white' }} to={'/my-tickets'}>{t('myTickets')}</Link>
                </div>}
                {(!user && !moderator) ?
                    <>
                        <div className="navbar-item">
                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/login'}>{t('login')}</Link>
                        </div>
                        <div className="navbar-item">
                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/register'}>{t('register')}</Link>
                        </div>
                    </>
                    :
                    <>

                        <div className="navbar-item">
                            <div style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} onClick={logoutHandler}>{t('logout')}</div>
                        </div>
                    </>
                }
                <div className="navbar-item">
                    <PublicIcon style={{ color: 'white', cursor: 'pointer' }} onClick={changeLanguage} />
                    {i18n.language === 'en' ? <span style={{ color: 'white', marginLeft: '5px', cursor: 'pointer' }} onClick={changeLanguage}>العربية</span> : <span style={{ color: 'white', marginLeft: '5px', cursor: 'pointer' }} onClick={changeLanguage}>En</span>}
                </div>
            </div>
        </div>
    )
}
