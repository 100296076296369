import React, { useEffect, useMemo, useReducer, useState } from 'react'
import styles from '../styles/userProfile.module.css';
import Navbar from '../components/Navbar';
import UserImage from '../assets/images/mostafa.jpeg';
import TextField from '../components/TextField';
import { reducer } from '../reducers/registerReducer';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneNumberUtil } from 'google-libphonenumber';
import countryList from 'react-select-country-list';
import validator from 'validator';
import { register } from '../redux/authSlice';
import PhoneNumber from '../components/PhoneNumber';
import Select from 'react-select';
import DatePicker from '../components/DatePicker';
import RadioButtonGroup from '../components/RadioButtonGroup';
import { t } from 'i18next';
import DropDownMenu from '../components/DropDownMenu';
import Button from '../components/Button';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const clubs = [
    'Al-Hilal',
    'Al-Ittihad',
    'Al-Taawoun',
    'AL NASSR',
    'Al-Ettifaq',
    'Al-Ahli',
    'Al-Wehda',
    'Al-Fateh',
    'Al-Feiha',
    'Al-Khaleej',
    'Al-Shabab',
    'Al-Ta\'ee',
    'Abha',
    'Damac',
    'Al-Akhdoud',
    'Al-Raed',
    'Al-Riyadh',
    'Al-Hazm'
  ];
  
  const club_id = {
    'Al-Hilal': '',
    'Al-Ittihad': '65158b1b8b0fcd363ccce621',
    'Al-Taawoun': '',
    'Al-Nassr': '65158b438b0fcd363ccce626',
    'Al-Ettifaq': '',
    'Al-Ahli': '',
    'Al-Wehda': '',
    'Al-Fateh': '',
    'Al-Feiha': '',
    'Al-Khaleej': '',
    'Al-Shabab': '65158b368b0fcd363ccce624',
    'Al-Ta\'ee': '65158b4e8b0fcd363ccce628',
    'Abha': '',
    'Damac': '',
    'Al-Akhdoud': '',
    'Al-Raed': '',
    'Al-Riyadh': '65158b998b0fcd363ccce62a',
    'Al-Hazm': '65158c418b0fcd363ccce62c'
  };
  
  const ERROR_MESSAGES = {
    EMAIL_EXISTS: "User email already exists",
    PHONE_EXISTS: "User Phone Number already exists",
    NATIONAL_ID_EXISTS: "User National ID already exists"
  };

export default function UserProfile() {
    const { user, isLoading, isSuccess, isError, message } = useSelector(state => state.auth);
    const [state, stateDispatch] = useReducer(reducer, {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        nationalId: '',
        nationality: '',
        city: '',
        address: '',
        gender: '',
        favoriteTeam: '',
        dateOfBirth: new Date('1/1/2000')
      });
    // const [state, stateDispatch] = useReducer(reducer, {
    //     firstName: user?.firstName,
    //     lastName: user?.lastName,
    //     email: user?.email,
    //     phoneNumber: user?.phoneNumber,
    //     nationalId: user?.nationalId,
    //     nationality: user?.nationality,
    //     city: user?.city,
    //     address: user?.address,
    //     gender: user?.gender,
    //     favoriteTeam: user?.favoriteTeam,
    //     dateOfBirth: new Date(user?.dateOfBirth)
    //   });
      const [confirmPassword, setConfirmPassword] = useState('');
      const [validPassword, setValidPassword] = useState(true);
      const [validEmail, setValidEmail] = useState(true);
      const [validPhone, setValidPhone] = useState(true);
      const [validNationalId, setValidNationalId] = useState(true);
      const [matchedPassword, setMatchedPassword] = useState(true);
      const [errorMsg, setErrorMsg] = useState('');
      const [edit, setEdit] = useState(false);
      const navigate = useNavigate();
      const dispatch = useDispatch();
      
      const phoneUtil = PhoneNumberUtil.getInstance();
      const isPhoneValid = (phoneNumber) => {
        try {
          return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
        } catch (error) {
          return false;
        }
      };
    
      const countryOptions = useMemo(() => countryList().getData(), []);
    
      const passwordHints = () => {
        return (
          <ul style={{ overflow: 'hidden', textAlign: 'start', color: 'red' }}>
            <li>Minimum length : 8</li>
            <li>Minimum lowercase letters : 1</li>
            <li>Minimum uppercase letters : 1</li>
            <li>Minimum numbers : 1</li>
            <li>Minimum symbols : 1</li>
          </ul>
        );
      }
    
    
      const validateEmail = (email) => {
        if (validator.isEmail(email)) {
          // console.log('Valid email');
          return true;
        }
        else {
          // document.querySelector('#email').focus();
          // console.log('Invalid email');
          return false;
        }
      }
    
      const validatePassword = (password) => {
        const constraints = {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1
        };
        if (validator.isStrongPassword(password, constraints)) {
          // console.log('Valid password');
          return true;
        }
        else {
          // console.log('Invalid password');
          return false;
        }
      }
    
      const matchPassword = (password, confirmPassword) => {
        return password === confirmPassword;
      }
    
      const validateNationalId = (nationalId) => {
        let id = nationalId.toString();
        let type = id.substr(0, 1);
        let sum = 0;
        const _idLength = 10;
        const _type1 = '1';
        const _type2 = '2';
        id = id.trim();
        if (isNaN(parseInt(id)) || (id.length !== _idLength) || (type !== _type2 && type !== _type1)) {
          return -1;
        }
        for (let num = 0; num < 10; num++) {
          const digit = Number(id[num]);
          if (num % 2 === 0) {
            const doubled = digit * 2;
            const ZFOdd = `00${doubled}`.slice(-2);
            sum += Number(ZFOdd[0]) + Number(ZFOdd[1]);
          } else {
            sum += digit;
          }
        }
        return (sum % 10 !== 0) ? -1 : type;
      }
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        // validation
        if (!state.firstName) {
          document.querySelector('#firstName').focus();
          return;
        }
        if (!state.lastName) {
          document.querySelector('#lastName').focus();
          return;
        }
        if (!validateEmail(state.email)) {
          document.querySelector('#email').focus();
          return;
        }
        if (!validatePassword(state.password)) {
          document.querySelector('#password').focus();
          return;
        }
        if (!matchPassword(state.password, confirmPassword)) {
          document.querySelector('#confirmPassword').focus();
          return;
        }
        if (!isPhoneValid(state.phoneNumber)) {
          return;
        }
        if (!state.nationality) {
          document.querySelector('#nationality').focus();
          return;
        }
        /*if (!validateNationalId(state.nationalId)) {
          if (state.nationality === "Saudi Arabia") {
            document.querySelector('#nationalId').focus();
          }
          else {
            document.querySelector('#eqamaId').focus();
          }
          return;
        }*/
        if (!state.city) {
          document.querySelector('#city').focus();
          return;
        }
        if (!state.dateOfBirth) {
          document.querySelector('#dateOfBirth').focus();
          return;
        }
        if (!state.gender) {
          document.querySelector('#gender').focus();
          return;
        }
        if (!state.favoriteTeam) {
          document.querySelector('#favoriteTeam').focus();
          return;
        }
    
        const user = {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          password: state.password,
          phoneNumber: state.phoneNumber,
          nationalId: +state.nationalId,
          nationality: state.nationality.label,
          city: state.city,
          address: state.address,
          gender: state.gender,
          dateOfBirth: state.dateOfBirth,
          favoriteTeam: club_id[state.favoriteTeam],
          tickets: [],
          profilePicture: ''
        };
    
        dispatch(register(user));
      }

      const updateUser = async(user) => {
        const config = {
          "Authorization":`Bearer`
        };

        try {
          const response = await axios.patch(`https://tazaker-back.onrender.com/api/users/${user}`, user);
          toast.success('Saved Changes');
          setEdit(false);
          return response.data.data.user;
        } catch (error) {
          toast.error(error.message);
          return error;
        }
      }

      const saveChanges = async(e) => {
        e.preventDefault();
        // validation
        if (!state.firstName) {
          document.querySelector('#firstName').focus();
          return;
        }
        if (!state.lastName) {
          document.querySelector('#lastName').focus();
          return;
        }
        if (!validateEmail(state.email)) {
          document.querySelector('#email').focus();
          return;
        }
        if (!validatePassword(state.password)) {
          document.querySelector('#password').focus();
          return;
        }
        if (!matchPassword(state.password, confirmPassword)) {
          document.querySelector('#confirmPassword').focus();
          return;
        }
        if (!isPhoneValid(state.phoneNumber)) {
          return;
        }
        if (!state.nationality) {
          document.querySelector('#nationality').focus();
          return;
        }
        /*if (!validateNationalId(state.nationalId)) {
          if (state.nationality === "Saudi Arabia") {
            document.querySelector('#nationalId').focus();
          }
          else {
            document.querySelector('#eqamaId').focus();
          }
          return;
        }*/
        if (!state.city) {
          document.querySelector('#city').focus();
          return;
        }
        if (!state.dateOfBirth) {
          document.querySelector('#dateOfBirth').focus();
          return;
        }
        if (!state.gender) {
          document.querySelector('#gender').focus();
          return;
        }
        if (!state.favoriteTeam) {
          document.querySelector('#favoriteTeam').focus();
          return;
        }
    
        const user = {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          password: state.password,
          phoneNumber: state.phoneNumber,
          nationalId: +state.nationalId,
          nationality: state.nationality.label,
          city: state.city,
          address: state.address,
          gender: state.gender,
          dateOfBirth: state.dateOfBirth,
          favoriteTeam: club_id[state.favoriteTeam],
        };
    
        // update request
        
        

      }

    //   const fetchUser = async(userId) => {
    //     try {
    //         const response = await axios.get(`https://tazaker-back.onrender.com/api/users/${userId}`);
    //         const user = response.data.data;
    //         console.log('fetched successfully')
    //         console.log(user)
    //         stateDispatch({ type: 'updateFirstName', payload: user.firstName });
    //         stateDispatch({ type: 'updateLastName', payload: user.lastName });
    //         stateDispatch({ type: 'updateEmail', payload: user.email });
    //         stateDispatch({ type: 'updatePhoneNumber', payload: user.phoneNumber });
    //         stateDispatch({ type: 'updateNationality', payload: user.nationality });
    //         stateDispatch({ type: 'updateNationalId', payload: user.nationalId });
    //         stateDispatch({ type: 'updateCity', payload: user.city });
    //         stateDispatch({ type: 'updateAddress', payload: user.address });
    //         stateDispatch({ type: 'updateDateOfBirth', payload: user.dateOfBirth });
    //         stateDispatch({ type: 'updateFavoriteTeam', payload: user.favoriteTeam });
            
    //         return response.data.data.user;
    //     } catch (error) {
    //       console.log(error.message)
    //         return error;
    //     }
    // }

    useEffect(()=>{
      if(!user){
        navigate('/login');
      }
      stateDispatch({ type: 'updateFirstName', payload: user.user.firstName });
      stateDispatch({ type: 'updateLastName', payload: user.user.lastName });
      stateDispatch({ type: 'updateEmail', payload: user.user.email });
      stateDispatch({ type: 'updatePhoneNumber', payload: user.user.phoneNumber });
      stateDispatch({ type: 'updateNationality', payload: user.user.nationality });
      stateDispatch({ type: 'updateNationalId', payload: user.user.nationalId });
      stateDispatch({ type: 'updateCity', payload: user.user.city });
      stateDispatch({ type: 'updateAddress', payload: user.user.address });
      stateDispatch({ type: 'updateDateOfBirth', payload: new Date(user.user.dateOfBirth) });
      stateDispatch({ type: 'updateFavoriteTeam', payload: user.user.favoriteTeam });
    },[user]);

    return (
        <div className={styles.profile}>
            <Navbar />
            {
              user ?
                  <div className={styles.profile_container}>
                      <div className={styles.img_container}>
                          <img className={styles.user_img} src={UserImage}/>
                          <h1>Youssef Mostafa</h1>
                      </div>
                      <form name='profile_form' onSubmit={(e) => {e.preventDefault()}}>
                          <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.first_name + ' ' + styles.text_field} disabled={!edit} value={state.firstName} type='text' id='firstName' label={t('firstName')} onChange={(e) => stateDispatch({ type: 'updateFirstName', payload: e.target.value })} />
                          <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.first_name + ' ' + styles.text_field} disabled={!edit} value={state.lastName} type='text' id='lastName' label={t('lastName')} onChange={(e) => stateDispatch({ type: 'updateLastName', payload: e.target.value })} />
                          <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.email + ' ' + styles.text_field + ' ' + ((validateEmail(state.email) || validEmail) ? '' : styles.invalid)} disabled={!edit} value={state.email} type='email' id='email' label={t('emailAddress')} onChange={(e) => { stateDispatch({ type: 'updateEmail', payload: e.target.value }); setValidEmail(validateEmail(e.target.value)); setErrorMsg('') }}>
                              {(validateEmail(state.email) || validEmail) || errorMsg === ERROR_MESSAGES.EMAIL_EXISTS ? '' : <span className={styles.error_msg}>Invalid email address</span>}
                              {errorMsg === ERROR_MESSAGES.EMAIL_EXISTS ? <span className={styles.error_msg}>{errorMsg}</span> : ''}
                          </TextField>
                          <PhoneNumber
                              label={t('phoneNumber')}
                              labelClassName={styles.label}
                              id='phoneNumber'
                              defaultCountry='sa'
                              disabled={!edit}
                              wrapperClassName={styles.form_group}
                              inputClassName={styles.text_field}
                              className={styles.phone_number}
                              inputStyle={{ fontSize: '16px' }}
                              value={state.phoneNumber}
                              onChange={(phoneNumber) => { stateDispatch({ type: 'updatePhoneNumber', payload: phoneNumber }); setValidPhone(isPhoneValid(phoneNumber) || phoneNumber === "+966 "); setErrorMsg('') }}
                          >
                              {(isPhoneValid(state.phoneNumber) || validPhone || errorMsg === ERROR_MESSAGES.PHONE_EXISTS) ? '' : <span className={styles.error_msg}>Phone Number is not valid</span>}
                              {errorMsg === ERROR_MESSAGES.PHONE_EXISTS ? <span className={styles.error_msg}>{errorMsg}</span> : ''}
                          </PhoneNumber>
                          <div className={styles.form_group + ' ' + styles.nationality} style={{ marginTop: '5px', marginBottom: '10px' }}>
                              <label className={styles.label} htmlFor='nationality' style={{ fontWeight: 'bold' }}> {t('nationality')} <span style={{ color: 'red' }}> *</span></label>
                              <div className={styles.nationality_select}>
                                  <Select placeholder='Choose country' isDisabled={!edit} id='nationality' options={countryOptions} value={state.nationality} onChange={(value) => {
                                      stateDispatch({ type: 'updateNationality', payload: value })
                                  }} />
                              </div>
                          </div>
                          {state.nationality?.label === 'Saudi Arabia' ?
                              <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.national_id + ' ' + styles.text_field + ' ' + ((validateNationalId(state.nationalId) === 1 || validNationalId) ? '' : styles.invalid)} disabled={!edit} value={state.nationalId} type='number' id='nationalId' label={t('nationalId')} onChange={(e) => { stateDispatch({ type: 'updateNationalId', payload: e.target.value }); setValidNationalId(validateNationalId(e.target.value) === 1); }} >
                              {(validateNationalId(state.nationalId) === 1 || validNationalId) ? '' : <span className={styles.error_msg}>National ID is not valid</span>}
                              </TextField>
                              : state.nationality ?
                              <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.national_id + ' ' + styles.text_field + ' ' + ((validateNationalId(state.nationalId) === 2 || validNationalId) ? '' : styles.invalid)} disabled={!edit} value={state.nationalId} type='number' id='eqamaId' label={t('eqamaId')} onChange={(e) => { stateDispatch({ type: 'updateNationalId', payload: e.target.value }); setValidNationalId(validateNationalId(e.target.value) === 2); }} >
                                  {(validateNationalId(state.nationalId) === 2 || validNationalId) ? '' : <span className={styles.error_msg}>Eqama ID is not valid</span>}
                              </TextField>
                              :
                              ''
                          }
                          <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.city + ' ' + styles.text_field} disabled={!edit} value={state.city} type='text' id='city' label={t('city')} onChange={(e) => stateDispatch({ type: 'updateCity', payload: e.target.value })} />
                          <TextField labelClassName={styles.label} wrapperClassName={styles.form_group} inputClassName={styles.address + ' ' + styles.text_field} disabled={!edit} value={state.address} type='text' id='address' label={t('address')} onChange={(e) => stateDispatch({ type: 'updateAddress', payload: e.target.value })} />
                          <DatePicker
                              wrapperClassName={styles.form_group}
                              containerClassName={styles.date_picker_container}
                              inputClassName={styles.text_field + ' ' + styles.date_of_birth}
                              id='dateOfBirth'
                              label={t('dateOfBirth')}
                              labelClassName={styles.label}
                              disabled={!edit}
                              datePickerWrapperClassName={styles.date_picker_wrapper}
                              value={state.dateOfBirth}
                              onSelect={(date) => stateDispatch({ type: 'updateDateOfBirth', payload: date })}
                          />
                          <DropDownMenu
                            wrapperClassName={styles.form_group}
                            className={styles.favorite_team + ' ' + styles.text_field}
                            label={t('favTeam')}
                            labelClassName={styles.label}
                            id='favoriteTeam'
                            options={clubs}
                            disabled={!edit}
                            value={state.favoriteTeam}
                            onChange={(e) => stateDispatch({ type: 'updateFavoriteTeam', payload: e.target.value })}
                          />
                          <div className={styles.buttons_container}>
                            <Button wrapperClassName={styles.button_container} disabled={!edit} className={styles.submit_button} text='Save Changes' type='submit' onClick={() => setEdit(false)} />
                            <Button wrapperClassName={styles.button_container}  className={styles.edit_button} text='Edit' type='' onClick={() => setEdit(true)} />
                          </div>
                          
                      </form>
                    </div>
              : 'Loading'
            }
          <ToastContainer
            theme='colored'
            pauseOnHover
            draggable
            pauseOnFocusLoss
            newestOnTop={false}
            hideProgressBar
            position="bottom-right"
            autoClose={1000}
            rtl={false}
            closeButton={false}
          />
        </div>
    )
}
