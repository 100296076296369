import React from 'react'
import styles from '../styles/userAvatar.module.css';
import UserImage from '../assets/images/mostafa.jpeg';

export default function UserAvatar() {
    return (
        <div className={styles.img_container}>
            <img className={styles.user_img} src={UserImage} alt='' />
        </div>
    )
}
