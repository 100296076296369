import Navbar from '../components/Navbar';
import TicketCard from '../components/TicketCard';
import Ticket from '../components/Ticket';
import { useSelector } from 'react-redux';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router';


export default function MyTicket() {
    const { ticketId } = useParams()
    const { user } = useSelector(state => state.auth);
    const [isLoading, setLoading] = useState(true);
    const [info, setInfo] = useState([]);


    const config = {
        headers: { Authorization: `Bearer ${user.token}` }
      };
    
    let render = false;
    const renderTicket = async () => {
        // this.setState({loading: true});
        try {
          console.log(user.user.id);
          console.log(config);
          const userRes = await axios.get(`https://tazaker-back.onrender.com/api/users/${user.user.id}`,config);
          console.log("user response is ");
          console.log(userRes);
          const ticket = await axios.get(`https://tazaker-back.onrender.com/api/tickets/${ticketId}`);
          console.log("ticket response is")
          console.log(ticket)
          let infoApi = {
            userName: `${userRes.data.data.firstName} ${userRes.data.data.lastName}`,
            phoneNumber: `${user.user.phoneNumber}`,
            userPic: `${ticket.data.data.personalPic}`,
            nationalId: `${userRes.data.data.nationalId}`,
            fixture: `${ticket.data.data.match.fixture}`,
            matchDate: `${ticket.data.data.matchDate}`,
            homeClubName: ticket.data.data.homeClubName,
            awayClubName: ticket.data.data.awayClubName,
            homeClubLogo: ticket.data.data.homeClubLogo,
            awayClubLogo: ticket.data.data.awayClubLogo,
            stadiumName: `${'stadiumm'}`,
            qrImg: `${ticket.data.data.qrcode}`,
            section: `${ticket.data.data.seat.section}`,
            row: `${ticket.data.data.seat.row}`,
            seat: `${ticket.data.data.seat.id}`,
            bookingId: `${ticket.data.data._id}`,
            price: "150"
          }
          setInfo(infoApi);
        } catch (err) {
          throw (err)
        }
        ;
        render = true;
        console.log(info);
        setLoading(false);
        // this.setState({loading: false});
      }

    useEffect(() => {
        renderTicket();
        
        }, [])
    // const info = {
    //     userName: "Mostafa ELsharnoby",
    //     phoneNumber: "01150293056",
    //     userPic: "https://sharnoby.club/img/mostafa.jpg",
    //     nationalId: "29901121701975",
    //     fixture: "GW2",
    //     matchDate: new Date(),
    //     homeClubName: "Alnasr",
    //     awayClubName: "Alhilal",
    //     homeClubLogo: "https://upload.wikimedia.org/wikipedia/ar/a/ac/Al_Nassr_FC_Logo.svg",
    //     awayClubLogo: "https://upload.wikimedia.org/wikipedia/commons/2/22/Al-Hilal-Logo.png",
    //     stadiumName: "Alnasr Stadium",
    //     qrImg: "https://pngimg.com/uploads/qr_code/qr_code_PNG2.png",
    //     section: "B17",
    //     row: "3",
    //     seat: "12",
    //     bookingId: "Tbafeq7",
    //     price: "150"
    // };
    // const { loading } = this.state;
    if(isLoading){
        return (
            <div className=''>
                <Navbar />
                {/* <h1>Alooooooooo</h1> */}
                <h1>astnaa ya negm</h1>
            </div>
        )
    }else{
        return (
            <div className=''>
                <Navbar />
                {/* <h1>Alooooooooo</h1> */}
                <Ticket info={info}/>
            </div>
        )
    }
    
}
