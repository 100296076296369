import React, { useEffect, useState } from 'react'
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';


import '../styles/bookTicket.css'
import { useLocation, useNavigate, useParams } from 'react-router'
import Navbar from '../components/Navbar';

import CloseIcon from '@mui/icons-material/Close';
import MatchSummary from '../components/MatchSummary';
import Button from '../components/Button';
import doubleTicket from '../assets/images/two-tickets.svg'
import { useSelector } from 'react-redux';
import axios from 'axios';
import useFetch from '../hooks/useFetch';

import HashLoader from "react-spinners/HashLoader";
// import { ObjectID } from 'bson';
// import { ObjectId } from 'mongodb'


export default function BookTicket() {
  let seatObj;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const mobileToken = searchParams.get("token");
  console.log("mobile, ", mobileToken)
  const navigate = useNavigate();

  useEffect(() => {
    console.log(mobileToken)
  }, [mobileToken])
  const { matchId } = useParams()

  const [match, setMatch] = useState()
  const [chart, setChart] = useState()
  const [seats, setSeats] = useState([])
  const [eventKey, setEventKey] = useState("")
  const [categoryPrice, setCategoryPrice] = useState([])
  const [seatPrice, setSeatPrice] = useState("100")


  const { user } = useSelector(state => state.auth);

  const renderMatch = async () => {
    try {
      const renderedMatch = await axios.get(`https://tazaker-back.onrender.com/api/match/${matchId}`)
      if (renderedMatch) {
        setMatch(renderedMatch.data)
      }
    } catch (err) {
      throw (err)
    }
  }


  const renderPricing = async () => {
    try {
      let clubId = (user.user.club === match.homeClub.clubName) ? match.homeClub._id : match.awayClub._id
      const renderedPricing = await axios.get(`https://tazaker-back.onrender.com/api/sectionCategory/categoryPrice/${matchId}?clubId=${clubId}`)
      if (renderedPricing) {
        setCategoryPrice(renderedPricing.data)
      }
    } catch (err) {
      throw (err)
    }
  }


  useEffect(() => {
    renderMatch()
  }, [])

  useEffect(() => {
    if (match)
      // setEventKey((user.user.club === match.homeClub.clubName) ? matchId + 'h' : matchId + 'a')
      setEventKey(matchId + 'a')

  }, [match])

  useEffect(() => {
    if (eventKey !== "") {
      // renderPricing()
    }
  }, [eventKey])

  const handleCancelSelectedSeat = async () => {
    try {
      await chart.clearSelection();
    } catch (err) {
      throw (err)
    }
  }


  const checkoutButtonHandler = async (e) => {
    e.preventDefault()

    console.log(user);
    const config = {
      headers: { Authorization: `Bearer ${user.token}` }
    };
    const userRes = await axios.get(`https://tazaker-back.onrender.com/api/users/${user.user.id}`, config);
    console.log("userRes");
    console.log(userRes);
    const re = await axios.post(`https://tazaker-back.onrender.com/api/seatio/book`, {
      "seats": seats,
      "eventKey": eventKey
    })
    console.log("re")
    console.log(re);
    let [sectionn, roww, idd] = seats.split('-');
    console.log("user photo is ");
    console.log(userRes.ticketPhoto);
    let ticket = {
      "match": match._id,
      "user": user.user.id,
      "seat": {
        "section": sectionn,
        "row": roww,
        "id": idd
      },
      "price": seatPrice,
      "homeClubName": match.homeClub.clubName,
      "homeClubLogo": match.homeClub.clubLogo,
      "awayClubName": match.awayClub.clubName,
      "awayClubLogo": match.awayClub.clubLogo,
      "matchDate": match.date,
      "matchFixture": match.fixture,
      "personalPic": userRes.data.data.ticketPhoto
    };
    console.log(ticket);
    const response = await axios.post('https://tazaker-back.onrender.com/api/tickets', ticket);
    console.log("Ticket Response");
    console.log(response)
    console.log(response.data.data._id)

    navigate(`/tickets/${response.data.data._id}`);
    // const ticketBody = {
    //   "userId": user.user.id,
    //   "matchId": eventKey,
    //   "seat": {
    //     "section": seats.split("-")[0],
    //     "row": seats.split("-")[1],
    //     "seatid": seats.split("-")[2],
    //   },
    //   "price": 180,

    // }

  }

  return (
    <>
      {mobileToken ? <h1>Mobile view, token {mobileToken} </h1> : <Navbar />}
      {/* <Navbar /> */}
      <div className="book-ticket-container">
        {eventKey === "" ?
          <div className="loader-container" style={{ height: "100vh" }}>
            <HashLoader
              color="#00fa3a"
              cssOverride={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}
              size={200}
            />
          </div>
          : <div className="book-ticket-list">
            <div className="left-side">
              <MatchSummary info={match} />
              <div className="stadium-chart">
                <div className="stadium-chart-text">
                  <b>Choose your seat</b>
                  <p>* Please select an area to see available seats</p>
                </div>

                <SeatsioSeatingChart
                  workspaceKey="d20c430b-5ee9-4dda-8bc9-c1da12dc448d"
                  event={eventKey}

                  onObjectSelected={obj => {
                    setSeats(obj.id)
                    seatObj = obj.label.split('-');
                    localStorage.setItem('section', seatObj[0]);
                    localStorage.setItem('row', seatObj[1]);
                    localStorage.setItem('seat', seatObj[2]);

                    // setSeatPrice(obj.pricing.price)
                  }}
                  onRenderStarted={createdChart => { setChart(createdChart) }}
                  onObjectDeselected={obj => setSeats([])}
                  maxSelectedObjects={1}
                  showFullScreenButton={false}
                  // pricing={categoryPrice}
                  // priceFormatter={(price) => {
                  //   return price + " SAR"
                  // }}
                  // language={"ar"}
                  region="eu"
                />
              </div>
            </div>
            <div className="right-side">
              <b>Booking Summary</b>
              {(seats.length > 0) ? <>
                <div className="seat-details">

                  <div className="details">
                    <img src={doubleTicket} alt="" style={{ height: "30px", width: "30px" }} />
                    <div className="seat-row">
                      {seats.split("-")[0]}

                      <p><strong>Row: </strong>{seats.split("-")[1]}    -    <strong>Seat: </strong>{seats.split("-")[2]}</p>
                    </div>
                  </div>
                  <p className="price">{seatPrice} SAR {<CloseIcon className="close-selected-seat" onClick={handleCancelSelectedSeat} />} </p>

                </div>
                <div className="pricing">
                  <div className="subtotal">
                    <p>Subtotal</p>
                    <p>{seatPrice} SAR</p>
                  </div>
                  <div className="vat">
                    <p>Vat</p>
                    <p>{0} SAR</p>
                  </div>
                  <div className="total">
                    <b>Total</b>
                    <b>{seatPrice} SAR</b>
                  </div>

                </div>
                <Button wrapperClassName={"button-container"} className={'to-checkout-button'} text={"Procceed to Checkout"} type={"submit"} onClick={(e) => checkoutButtonHandler(e)} />
              </> :
                <div className="double-ticket">
                  <img src={doubleTicket} alt="" />
                  <p className="double-ticket-text">
                    Select a seat to continue
                  </p>
                </div>
              }
            </div>
          </div>}
      </div>
    </>
  )
}
