export const reducer = (state, action) => {
    switch (action.type) {
        case 'updateFirstName':
            return {
                firstName: action.payload,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updateLastName':
            return {
                firstName: state.firstName,
                lastName: action.payload,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updateEmail':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: action.payload,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updatePassword':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: action.payload,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updatePhoneNumber':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: action.payload,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updateNationalId':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: action.payload,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updateNationality':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: action.payload,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updateCity':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: action.payload,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
        case 'updateAddress':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: action.payload
            };
        case 'updateGender':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: action.payload,
                address: state.address
            };
        case 'updateFavoriteTeam':
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: action.payload,
                gender: state.gender,
                address: state.address
            };
        case 'updateDateOfBirth':
            return {
                dateOfBirth: action.payload,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                city: state.city,
                favoriteTeam: action.payload,
                gender: state.gender,
                address: state.address
            };
        default:
            return {
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber,
                nationalId: state.nationalId,
                nationality: state.nationality,
                dateOfBirth: state.dateOfBirth,
                city: state.city,
                favoriteTeam: state.favoriteTeam,
                gender: state.gender,
                address: state.address
            };
    }
}