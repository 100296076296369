import React from 'react'

export default function TextField(props) {
    const { wrapperClassName, labelClassName, inputClassName, label, required, type, id, onChange, disabled, value } = props;
    return (
        <div className={wrapperClassName}>
            <label className={labelClassName} htmlFor={id} style={{ fontWeight: 'bold' }}>{label}{required ? <span style={{ color: 'red' }}> *</span> : ''}</label>
            <input className={inputClassName} type={type} id={id} onChange={onChange} disabled={disabled} value={value}/>
            {props.children}
        </div >
    )
}
