import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function TournamentDropDown(props) {

    const [t, i18n] = useTranslation();

    const data = [
        "SaudiProLeague",
        "Saudi Cup"
    ]
    const { selectedTournament } = props
    let reset = props.reset
    const [dropDownTiltle, setDropDownTitle] = useState("all")
    useEffect(() => {
        setDropDownTitle("all")

    }, [reset])


    const getSelectedTournament = (tournament) => {
        selectedTournament(tournament)
        setDropDownTitle(tournament)
    }

    return (
        <div className="dropdown">
            <button className="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {t(dropDownTiltle)}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {data.map((tournament, index) => {
                    return (<li key={index} onClick={() => getSelectedTournament(tournament)}>
                        <div className="container">
                            <div className="name">{t(tournament.replace(/\s/g, ''))}</div>
                        </div>
                    </li>)
                })}


            </ul>
        </div>
    )
}
