import React, { useState } from 'react'
import useFetch from '../hooks/useFetch'
import MatchCard from '../components/MatchCard'
import MatchFilter from '../components/MatchFilter/MatchFilter'

import '../styles/matchList.css'

import Skeleton from 'react-loading-skeleton'
import ContentLoader from "react-content-loader"


export default function MatchList() {
    const { data: matches, loading, error } = useFetch(`/match/`)

    const [filteredMatches, setFilteredMatches] = useState([])

    return (
        <div className='matchs'>
            <div className="matchs-container">
                <MatchFilter setMatches={setFilteredMatches} />
                {(filteredMatches.length > 0) ? (
                    <div className="matchs-list">
                        {filteredMatches.map((match) => {
                            return <MatchCard key={match._id} matchInfo={match} />
                        })}
                    </div>
                ) :
                    (<div className="matchs-list">
                        {(loading) ?
                            <ContentLoader
                                speed={2}
                                width={2000}
                                height={580}
                                viewBox="0 0 2000 580"
                                backgroundColor="#f3f3f3"
                                foregroundColor="#00fa3a"
                            >
                                <rect x="10" y="20" rx="0" ry="0" width="1500" height="145" />
                                <rect x="10" y="195" rx="0" ry="0" width="1500" height="145" />
                                <rect x="10" y="370" rx="0" ry="0" width="1500" height="145" />
                            </ContentLoader>
                            : (matches.length > 0) ?
                                matches.map((match) => {
                                    return <MatchCard key={match._id} matchInfo={match} />
                                }) :
                                <h1 className="no-matches" style={{ margin: "100px" }}>No matches to show !</h1>
                        }
                    </div>)
                }

            </div>
        </div>
    )
}
