import React from 'react'
import RadioButton from './RadioButton';

export default function RadioButtonGroup(props) {
    const { wrapperClassName, label, required, radioButtonsContainerClassName, radioWrapperClassName, groupName, values, onChange, id } = props;
    return (
        <div className={wrapperClassName}>
            <label htmlFor={groupName} style={{ fontWeight: 'bold' }}>{label}{required ? <span style={{ color: 'red' }}> *</span> : ''}</label>
            <div className={radioButtonsContainerClassName}>
                {values.map((value) => <RadioButton wrapperClassName={radioWrapperClassName} inputClassName={''} name={groupName} id={id} value={value} onChange={onChange} />)}
            </div>
        </div>
    )
}
