import React from 'react'
import Navbar from '../components/Navbar'
import styles from '../styles/passwordRecovery.module.css';
import TextField from '../components/TextField';
import { useState } from 'react';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import { CgSpinner } from 'react-icons/cg';
import OtpInput from 'react-otp-input';
import { useEffect } from 'react';
import axios from 'axios';


export default function PasswordRecovery() {
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [t, i18n] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [OTP, setOTP] = useState('');
    const [timer, setTimer] = useState(60);
    const [disableResend, setDisableResend] = useState(true);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        if (validator.isEmail(email)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // setTimeout(() => { setIsLoading(false); toast.success('Code sent successfully'); setShowOTP(true) }, 2000);

        // validation
        if (!email || !validateEmail(email)) {
            setValidEmail(false);
            document.querySelector('#email').focus();
            return;
        }

        try {
            const response = await axios.post('https://tazaker-back.onrender.com/api/email/reset-password', { email });
            toast.success('Verification email has been sent successfully');
            setIsLoading(false);
            navigate('/reset-password');
        } catch (error) {
            toast.error(error.message);
            return error;
        }

        // setIsLoading(false);
        // navigate('/reset-password');
    }

    const handleVerify = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setTimeout(() => { setIsLoading(false); navigate('/reset-password'); }, 2000);
    }

    useEffect(() => {
        let interval = setInterval(() => {
            setTimer((lastCount) => {
                lastCount <= 1 && clearInterval(interval);
                if (lastCount <= 1) { setDisableResend(false) }
                if (lastCount <= 0) { return lastCount }
                return lastCount - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [disableResend]);

    return (
        <div className={styles.password_recovery_page}>
            <Navbar />
            {!showOTP ?
                <div className={styles.password_recovery_container}>
                    <h1>Password Recovery</h1>
                    <p>Enter the email address associated with your account</p>
                    <form name='password_recovery_form' onSubmit={handleSubmit}>
                        <TextField wrapperClassName={styles.form_group} inputClassName={styles.email + ' ' + styles.text_field + ' ' + (validEmail ? '' : styles.invalid)} type='email' id='email' label={t('emailAddress')} onChange={(e) => { setEmail(e.target.value); setValidEmail(validateEmail(e.target.value)) }}>
                            {validEmail ? '' : <span className={styles.error_msg}>Invalid email address</span>}
                        </TextField>
                        <Button wrapperClassName={styles.button_container} className={styles.submit_button} disabled={isLoading} text={isLoading ? <CgSpinner size={25} className={styles.spinner} /> : t('submit')} />
                    </form>
                </div>
                :
                <div className={styles.otp_wrapper}>
                    <h1>OTP Verification</h1>
                    <p>Verify your email address</p>
                    <OtpInput
                        value={OTP}
                        onChange={setOTP}
                        numInputs={6}
                        renderSeparator={<span style={{ width: "8px" }}></span>}
                        shouldAutoFocus={true}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={styles.otp_container}
                        inputStyle={styles.otp_input}
                    />
                    <Button wrapperClassName={styles.button_container} onClick={handleVerify} className={styles.verify_button} disabled={isLoading} text={isLoading ? <CgSpinner size={25} className={styles.spinner} /> : 'Verify'} />
                    <span style={{ color: 'black' }}>Did not receive code? <Link style={{ color: disableResend ? 'black' : '', textDecoration: 'none', cursor: disableResend ? 'not-allowed' : 'pointer' }}>Resend code</Link>{disableResend ? ` in ${timer} seconds` : ``}</span>
                </div>
            }


            <ToastContainer
                theme='colored'
                pauseOnHover
                draggable
                pauseOnFocusLoss
                newestOnTop={false}
                hideProgressBar
                position="bottom-right"
                autoClose={1000}
                rtl={false}
                closeButton={false}
            />
        </div>
    )
}
