import React from 'react'

export default function RadioButton(props) {
    const { wrapperClassName, inputClassName, name, value, onChange, id } = props;
    return (
        <div className={wrapperClassName}>
            <input type='radio' name={name} id={id} value={value} onChange={onChange} style={{ marginRight: '10px' }} />{value}
        </div>
    )
}
