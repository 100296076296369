import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import MyTickets from './pages/MyTickets';
import Login from './pages/Login';
import Register from './pages/Register';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Ticket from './pages/Ticket';
import BookTicket from './pages/BookTicket';
import PasswordRecovery from './pages/PasswordRecovery';
import NewPassword from './pages/NewPassword';
import MyTicket from './pages/Ticket';
import PhoneOTPValidation from './pages/PhoneOTPValidation';
import UserProfile from './pages/UserProfile';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';


function App() {
    return (
        <div className="App" >
            <Routes >
                <Route path='/phone-verification' element={< PhoneOTPValidation />} />
                <Route path='/profile' element={< UserProfile />} />
                <Route path='/' element={< Home />} />
                <Route path='/my - tickets' element={< MyTickets />} />
                <Route path='/login' element={< Login />} />
                <Route path='/register' element={< Register />} />
                <Route path='/bookTicket /: matchId' element={< BookTicket />} />
                <Route path='/tickets/:ticketId' element={< MyTicket />} />
                <Route path='/password-recovery' element={< PasswordRecovery />} />
                <Route path='/reset - password' element={< NewPassword />} />
            </Routes >
        </div>
    );
}

export default App;