import React from 'react'
import ReactDatePicker from 'react-datepicker';

export default function DatePicker(props) {
    const { wrapperClassName, labelClassName, inputClassName, containerClassName, disabled, required, label, id, datePickerWrapperClassName, value, onSelect } = props;
    return (
        <div className={wrapperClassName}>
            <label className={labelClassName} htmlFor={id} style={{ fontWeight: 'bold' }}>{label}{required ? <span style={{ color: 'red' }}> *</span> : ''}</label>
            <div className={containerClassName}>
                <ReactDatePicker
                    wrapperClassName={datePickerWrapperClassName}
                    className={inputClassName}
                    selected={value}
                    disabled={disabled}
                    onSelect={onSelect}
                    id={id}
                    showYearDropdown
                />
            </div>
        </div>
    )
}
