import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import '../styles/home.css'
import Header from '../components/Header'
import MatchList from './MatchList'
import MatchCrud from './ADMIN_CRUD/MatchCrud'
import ClubCrud from './ADMIN_CRUD/ClubCrud'
import MatchFilter from '../components/MatchFilter/MatchFilter'
import Ticket from '../components/Ticket'
import NotAllowedPopUp from '../components/NotAllowedPopUp'
import axios from 'axios'
import CameraComponent from '../components/Camera'
import AdminView from './ADMIN_CRUD/AdminView'
import ModeratorView from './MODERATOR_CRUD/ModeratorView'
import Footer from '../components/Footer'


export default function Home() {
    const info = {
        userName: "Mostafa ELsharnoby",
        phoneNumber: "01150293056",
        userPic: "https://sharnoby.club/img/mostafa.jpg",
        nationalId: "29901121701975",
        fixture: "GW2",
        matchDate: new Date(),
        homeClubName: "Alnasr",
        awayClubName: "Alhilal",
        homeClubLogo: "https://upload.wikimedia.org/wikipedia/ar/a/ac/Al_Nassr_FC_Logo.svg",
        awayClubLogo: "https://upload.wikimedia.org/wikipedia/commons/2/22/Al-Hilal-Logo.png",
        stadiumName: "Alnasr Stadium",
        qrImg: "https://pngimg.com/uploads/qr_code/qr_code_PNG2.png",
        section: "B17",
        row: "3",
        seat: "12",
        bookingId: "Tbafeq7",
        price: "150"
    };
    return (
        <div className='home-page'>
            <Navbar />
            <Header />
            <div className="home-page-container">
            </div>
            {/* <Ticket info={info}/> */}
            {/* <MatchCard matchInfo={match}/> */}
            {/* <CameraComponent /> */}
            <MatchList />
            {/* <ClubCrud /> */}
            {/* <MatchCrud /> */}
            {/* <NotAllowedPopUp /> */}

            {/* <Chart /> */}

            {/* <OfferTicket /> */}
            {/* <ModeratorView /> */}

            {/* <AdminView /> */}
            <Footer />

        </div>
    )
}
