import React from 'react'
import '../styles/header.css'
import Navbar from './Navbar'
import { useTranslation } from 'react-i18next'

export default function Header() {
    const [t,i18n] = useTranslation();
    return (
        <div className='header'>
            <div className="header-container">
                <div className="header-list">
                    <div className="header-item">
                        <h1>{t('headerTitle')}</h1>
                    </div>
                    <div className="header-item">
                        <p>{t('headerBody')}</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
