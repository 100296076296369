import React from 'react'
import '../styles/matchSummary.css'
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useTranslation } from 'react-i18next';

export default function MatchSummary(props) {
    const [t, i18n] = useTranslation();


    const { homeClub, awayClub, stadium, fixture, date, leagueName, _id: eventKey } = props.info
    const { customStyle } = props
    console.log(date)
    return (
        <div className="match-summary" style={customStyle}>
            <div className="match-date">
                <p className="month">{new Date(date).toLocaleString('default', { month: 'long' })}</p>
                <b className="day">{new Date(date).toLocaleString('default', { day: '2-digit' })}</b>
            </div>
            <div className="clubs-data-stadium">
                <p>
                    <strong>{t(JSON.stringify(homeClub.clubName))}</strong > vs <strong>{t(JSON.stringify(awayClub.clubName))}</strong>
                </p>
                <div className="date-stadium">
                    <p>{<CalendarMonthOutlinedIcon />} {new Date(date).toDateString()} | {new Date(date).toLocaleTimeString([], { timeStyle: 'short' })}</p>
                    <p> {<StadiumOutlinedIcon />} {stadium.name}</p>
                </div>
            </div>
        </div>
    )
}
