export const reducer = (state, action) => {
    switch (action.type) {
        case 'updateEmail':
            return {
                email: action.payload,
                password: state.password,
                phoneNumber: state.phoneNumber
            };
        case 'updatePhoneNumber':
            return {
                email: state.email,
                password: state.password,
                phoneNumber: action.payload
            };
        case 'updatePassword':
            return {
                email: state.email,
                password: action.payload,
                phoneNumber: state.phoneNumber,
            };
        default:
            return {
                email: state.email,
                password: state.password,
                phoneNumber: state.phoneNumber
            };
    }
}